<template>
  <div class="alert_line">
    <div class="code_box">
      <img :src="codes" alt />
    </div>
    <div class="close" @click="sendMsg"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: ''
    };
  },

  mounted: function() {
      //二維碼
    this.getHttp(this, '', "/code", function(obj, data) {
        obj.codes = data.line
    });
  },
  methods: {
    sendMsg() {
      this.$emit("func", false);
    }
  }
};
</script>
<style lang="scss" scope>
.alert_line {
  width: 10.2rem;
  height: 6.7rem;
  position: fixed;
  left: 50%;
  margin-left: -5.1rem;
  top: 50%;
  margin-top: -3.35rem;
  z-index: 1000;
  background: url(../assets/img/line.png) no-repeat;
  background-size: 100% 100%;
  .code_box {
    width: 2.86rem;
    height: 2.86rem;
    position: absolute;
    top: 1.3rem;
    left: 50%;
    margin-left: -1.43rem;
    // background: url(../assets/img/codebg1.png) no-repeat;
    // background-size: 100% 100%;
    text-align: center;
    line-height: 2.68rem;
    img {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
  .close {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    right: 0.75rem;
    top: 0.2rem;
  }
}
</style>