<!--
 * @Descripttion: 
 * @version: 
 * @Author: liy
 * @Date: 2023-03-29 17:53:57
 * @LastEditTime: 2023-04-03 10:51:53
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html,
body {
  margin: 0;
  padding: 0;
  margin-top: .75rem;
  
}
</style>
