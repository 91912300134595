<template>
  <div>
    <el-dialog custom-class="cancel-dialog dialog-bg-m"
               :show-close="false" center :append-to-body="true" title="註銷提醒"
               :visible.sync="isAccountVisible"
               :before-close="closeDialog"
               width="30%"
    >
      <p style="word-break: break-word;text-align: justify-all; font-size: 18px">如果您不再使用此帳號，可以將其註銷。<br/>
        帳號成功註銷後， <span style="color: red">該帳號下所有遊戲</span>的個人資訊（包括但不限頭像、昵稱、充值和消費記錄、好友關係、歷史戰績、段位與等級等）將進行刪除或匿名化處理，
        <span style="color: red">且無法恢復</span>
        ，請您謹慎操作。
      </p>
      <span slot="footer" class="dialog-footer">
         <el-button size="medium" type="primary"
                    @click="nextCancel">確認註銷</el-button>
         <el-button size="medium" @click="closeDialog">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog custom-class="cancel-dialog dialog-bg-s" center :append-to-body="true"
               :visible.sync="show_confirm_success"
               :show-close="false"
               width="30%"
               :before-close="closeDialog">
      <p style="text-align: center;position: relative; top: 0.4rem;">註銷成功</p>
      <span slot="footer" class="dialog-footer">
         <el-button type="primary" size="medium" @click="finishDeaction">確認</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {Button, Dialog} from "element-ui";
import store from "../../pcStore";
import axios from "axios";

export default {
  props: {
    // show_account_cancel: Boolean,
    gamesList: Array
  },
  data() {
    return {
      isLolading: false,
      type: 'apple',
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 'auto',
        spaceBetween: 1,
        grabCursor: true,
        autoHeight: true,
        observeParents: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
      },
      pk_userInfo: {},
      show_confirm_success: false,
      isAccountVisible: false
    }
  },
  computed: {},
  beforeMount() {
    this.$watch(
        () => store.state.show_account_cancel,
        (newV, oldV) => {
          this.isAccountVisible = newV
        }
    )
  },
  mounted() {
  },
  methods: {
    nextCancel() {
      this.deactiveAccount()
      store.commit('setAccountDeletion', false)
    },
    closeDialog() {
      store.commit('setAccountDeletion', false)
    },
    async deactiveAccount() {
      this.$layer.msg('該帳號已註銷，請重新登錄')
      this.pk_userInfo = JSON.parse(localStorage.getItem('pk_userInfo'))
      this.postHttp(this, {token: this.pk_userInfo.token}, '/deletionAccount', function (obj, res) {
        const {code, data, message} = res
        if(code === 200) {
          obj.$layer.msg('該帳號已註銷，請重新登錄')
          obj.show_confirm_success = true
        }else {
          obj.$layer.msg(message);
        }
      })
    },
    finishDeaction() {
      this.show_confirm_success = false
      store.commit('logOut')
    },
  }
}
</script>
<style lang="scss" scoped>
.login_wrapper {
  display: flex;
  align-items: flex-start;
  height: 1rem;
  margin: auto;

  .swiper-container {
    list-style: none;
    padding: 0;
    z-index: 0;
    width: 7rem;
    height: 1.7rem;
    text-align: center;
  }

  .swiper-wrapper {
    height: 1.5rem;
  }

  .swiper-slide {
    width: 1rem;
    height: 1.5rem;
    flex: 1;
    text-align: center;
    margin-left: 0.1rem;
  }

  .swiper-slide p {
    color: #333333;
    font-size: 16px;
  }

  .swiper-slide img {
    width: 1rem;
    height: 1rem;
    border-radius: 0.1rem;
    transition: transform 0.9s ease 0s;
  }

  .swiper-slide img:hover {
    transform: scale(1.05, 1.05);
    animation: 0.9s ease-in-out;
  }
}

.swiper-button-prev {
  width: 0.4rem !important;
  height: 0.6rem !important;
  background: url('~@/assets/img/prevfb.png') no-repeat !important;
  background-size: 100% 100% !important;
  left: 17px !important;
  outline: none !important;
  margin-top: -2px !important;
}

.swiper-button-next {
  width: 0.4rem !important;
  height: 0.6rem !important;
  background: url('~@/assets/img/nextfb.png') no-repeat !important;
  background-size: 100% 100% !important;
  right: 17px !important;
  outline: none !important;
  margin-top: -2px !important;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '';
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '';
}

.swiper {
  margin-top: 5%;
  width: 100%;
}

.thirdLogin {
  position: relative;
  width: 229px;
  height: 63px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  a {
    content: '';
    width: 64px;
    height: 64px;
    background: url('~@/assets/img/btn_third_login.png') no-repeat center;
    background-size: 229px 63px;
    opacity: 1;
    margin-left: calc((229px - 64px) / 2);

    &:hover {
      opacity: 0.85;
    }

    &:nth-child(1) {
      left: 0;
      background-position: 0 0;
    }

    &:nth-child(2) {
      left: 50%;
      margin-left: -32px;
      background-position: -84px 0;
    }

    &:nth-child(3) {
      right: 0;
      background-position: -166px 0;
    }
  }
}
.van-toast {
  z-index: 10000 !important;
}

.cancel-dialog {
  box-shadow: unset !important;

  p {
    font-size: 18px;
    margin-top: 0.1rem;
    line-height: 26px;
  }

  a {
    color: #fcc916;
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .el-button {
    &:hover {
      color: #787878;
      border-color: #fcc916;
      background-color: #fff;
    }
  }

  .el-input {
    width: 366px;
  }
}
</style>
<style lang="scss">
.van-toast {
  z-index: 10000 !important;
}

.cancel-dialog {
  border-radius: 22px !important;

  .el-button--primary {
    background-color: #fcc916;
    border-color: #fcc916;
  }

  .el-message-box__status.el-icon-warning {
    color: #fcc916;
  }

  .el-button--primary:focus,
  .el-button--primary:hover {
    background-color: #fcc916;
    border-color: #fcc916;
  }

  .el-dialog__header {
    padding: unset;
  }

  .el-dialog__title {
    font-size: 22px;
  }

  .el-dialog__footer {
    padding: 10px 20px 20px 10px;
  }
}

</style>