<template>
  <div>
    <m-login @closeLogin="closeLogin"  :showLoginModal="showLoginModal" ref="mLogin"/>
    <transition name="right" appear>
      <div class="mb-charge">
        <go-back/>
        <div class="store_value">
          <div class="top">
            <img src="../assets/img/mb_store/chargebg.png" alt="">
            <m-footer class="mb-footer"/>
          </div>
          <div class="center">
            <div class="item" v-for="(item, index) in proList" :key="index">
              <img :src="getImgUrl(item.proSrc)" alt>
              <a href="javascript:" @click="gotoStore(item.id)">
                US${{ item.price }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showLoginSuccess" class="mb_login">
        <div class="close_pop result-top" @click="showLoginSuccess = false"></div>
        <img class="success-pop" alt src="../assets/img/successPop.png"/>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showLoginFailed" class="mb_login">
        <div class="close_pop result-top" @click="closeFailed"></div>
        <img class="failed-pop" alt src="../assets/img/login/failedbg.png"/>
        <p>{{ login_error }}</p>
      </div>
    </transition>
  </div>
</template>
<script>
import '../assets/css/mbCommon.scss';
import GoBack from "./goBack.vue";
import MFooter from "@/components/mb_footer.vue";
import MLogin from "@/components/mb_login.vue";
import {proList} from "../utils/proList";
import {getHashSearchParam} from "../utils/common";

export default {
  components: {
    GoBack, MFooter, MLogin
  },
  name: 'MCharge',
  watch: {},
  data() {
    return {
      proList: proList,
      showLoginModal: false,
      showLoginSuccess: false,
      showLoginFailed: false,
      login_error: ''
    }
  },
  methods: {
    async thirdParams(){
      let params
      let account = getHashSearchParam('account')
      let type = getHashSearchParam('type')
      console.log(type)
      if(type === 'google') {
        params = {
          account: account.substring(3, account.length),
          password: '',
          type: 'Google'
        }
        this.thirdLogin(params)
      }
    },
    thirdLogin(params){
      this.$refs.mLogin.confirmLogin(params)
    },
    closeLogin() {
      this.showLoginModal = false
    },
    gotoStore(pid) {
      let token = ''
      let uuid = ''
      if (localStorage.getItem('pk_userInfo')) {
        let userInfo = JSON.parse(localStorage.getItem('pk_userInfo'))
        token = userInfo.token
        uuid = userInfo.uuid
      } else {
        this.showLoginModal = true
        return
      }
      const app_id = '0xlnxs'
      const product_id = pid
      const extInfo = 'webPay'
      // const redirect_url = 'http://192.168.93.139:8080/#/mbCharge'
      const redirect_url = 'https://home.lukycube.com/#/mbCharge'

      const newLink = `https://lukycube.com/webapi/payments?token=${token}&app_id=${app_id}&uuid=${uuid}&product_id=${product_id}&extInfo=${extInfo}&device_type=5&redirect_url=${redirect_url}`
      location.href = newLink
    },
    getImgUrl(url) {
      return require("../assets/img/mb_store/" + url + '.png')
    }
  },
  mounted() {
    this.thirdParams()
  }
}
</script>
<style lang="scss" scoped>
$background-size: 100% 100%;
.mb-charge{
  font-family: SC-Bold, serif;
}
.top {
  position: absolute;
  top: 0;
  left: 0;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .item {
    position: relative;
    left: 0;
    top: 6rem;

    img {
      width: 9.4533rem;
      margin-top: .2rem;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      position: absolute;
      top: 50%;
      right: 2.9333vw;
      transform: translate(0, -50%);
      width: 2.6133rem;
      height: .84rem;
      line-height: .84rem;
      color: #a06b17;
      font-size: .3467rem;
      background: url('../assets/img/mb_store/btn_pro.png');
      background-size: 100% 100%;
    }
  }
}

.mb-footer {
  position: relative;
  bottom: 0;
}
</style>