<template>
  <div class="mb_showdown" v-if="showdownin">
      <p>推薦使用手机豎屏瀏覽哦~</p>
  </div>
</template>

<script>

export default {
  data() {
    return {
        showdownin:false,
    };
  },
  created:function(){
      var that = this
       window.addEventListener('resize',this.orient)
  },
  mounted: function() {
      var a =this
      a.orient()
   
    
  },
  methods: {
    orient() {
    if (window.orientation == 90 || window.orientation == -90) {
        console.log(1)
    //   dom.style.display = 'block';
      this.showdownin = true
    } else if (window.orientation == 0 || window.orientation == 180) {
    //   dom.style.display = 'none';
      this.showdownin = false
    }
  }
  }
};
</script>
<style lang="scss" scope>
.mb_showdown{
   width: 100%;
      height: 100%;
      position: fixed;
      text-align: center;
      color: white;
      font-size: .6rem;
      line-height: 100vh;
      top: 0;
      z-index: 9099;
      left: 0;
      background-color: rgba(0, 0, 0, .7);
}
</style>