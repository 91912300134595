<template>
  <div class="mb-custom">
    <go-back />
    <img alt class="logo" src="../assets/img/logo.png" />
    <img alt src="../assets/img/customer/customBg.png" class="mb_custom_bg" />
    <div class="mb-custom-menu">
      <img alt src="../assets/img/customer/mbContact.png" @click="fbContact" />
      <img
        alt
        src="../assets/img/customer/mbRule.png"
        @click="$router.push({ path: '/mbCustomerRule' })"
      />
      <img alt src="../assets/img/customer/mbFreecoin.png" @click="showFreeCoin = true" />
    </div>
    <m-footer />

    <div class="mask" v-if="showFreeCoin" @click="showFreeCoin = false"></div>
    <transition name="fade">
      <div class="mb_free_coin" v-if="showFreeCoin">
        <div class="close_pop" @click="closeFreeCoin"></div>
        <img class="customer-bg" alt src="../assets/img/login/costumerbg.png" />
        <div class="mb-custom-menu menu-top">
          <img alt src="../assets/img/login/line.png" @click="goLink('line')" />
          <img alt src="../assets/img/login/whats.png" @click="goLink('WhatsApp')" />
          <img alt src="../assets/img/login/telegram.png" @click="goLink('Telegram')" />
          <p>聯係客服領取金幣！</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import goBack from "./goBack.vue";
import MFooter from "./mb_footer.vue";
import "../assets/css/mbCommon.scss";

export default {
  components: { goBack, MFooter },
  data() {
    return {
      showFreeCoin: false,
      links: {
        line: "https://line.me/ti/p/s-0lV5MqZp",
        WhatsApp: "https://wa.me/+886979167925",
        Telegram: "https://t.me/TG521uu",
      },
    };
  },
  methods: {
    closeFreeCoin() {
      this.showFreeCoin = false;
    },
    goLink(key) {
      window.open(this.links[key]);
    },
    fbContact() {
      window.open("https://www.facebook.com/BigCard.Club");
    },
  },
};
</script>
<style lang="scss" scoped>
$background-size: 100% 100%;
.mb_free_coin {
  position: fixed;
  top: 16%;
  left: 0;
  width: 100%;
  height: 10.4267rem;
  z-index: 3;
}

.mb_custom_bg {
  width: 10rem;
  object-fit: cover;
}

.mb-custom-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10rem;
  left: 0.5rem;

  img {
    width: 9rem;
    height: 2.2rem;
    position: relative;
  }

  p {
    font-size: 0.75rem;
    color: #8184ff;
    width: 8rem;
    margin: auto;
    text-align: center;
    position: relative;
    top: 1rem;
    left: 0.25rem;
  }
}

.menu-top {
  top: 1.2rem;
  img {
    height: 2.3rem;
  }
}
</style>
