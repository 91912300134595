/*
 * @Descripttion:
 * @version:
 * @Author: liy
 * @Date: 2023-03-29 17:53:58
 * @LastEditTime: 2023-04-03 10:03:03
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import layer from 'vue-layer'
import axios from 'axios'
import 'vue-layer/lib/vue-layer.css'
import '@/assets/pcindex.css'
import '@/assets/js/flexible'
import "swiper/css/swiper.css";
import '@/assets/css/common.css'
import "element-ui/lib/theme-chalk/index.css";
import {  Button, Dialog, Icon } from "element-ui";

Vue.use(Button)
   .use(Dialog)
   .use(Icon)

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue);
/* axios配置 */
//axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$axios = axios
var querystring = require('querystring');
var baseUrl = 'https://dpkadmin.lukycube.com/api'

var os = function () {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();

if (os.isTablet) {
    var doc = document.documentElement;
    var Resize = function () {
        doc.style.fontSize = doc.clientWidth / 10 + 'px';
    }
    doc.style.fontSize = doc.clientWidth / 10 + 'px';
    window.addEventListener('resize', Resize, false);
}
/*
 * 封装ajax
 * obj : 全局this
 * data : ajax传入后台data数据
 * address : ajax接口地址
 * fn : 成功返回方法  带参数  obj,data  obj : this data : response
 * */
Vue.prototype.postHttp = function (obj, data, address, fn) {
    obj.$axios.post(baseUrl + address, querystring.stringify(data)).then(response => {
        fn(obj, response.data);
    }, response => {

    })
}
Vue.prototype.getHttp = function (obj, data, address, fn) {
    obj.$axios.get(baseUrl + address, {params: data}).then(function (response) {
        fn(obj, response.data)
    }).catch(function (response) {
        console.log(response);//发生错误时执行的代码
    });
}

new Vue({
    router, store,
    render: h => h(App),
}).$mount('#app')
