<template>
  <div class="mb_produce_main">
    <m-showdown></m-showdown>
    <img src="../assets/img/mobilegame.png" alt="" class="mb_produce_imgbg" />
    <div class="goback" @click="goback"></div>
    <m-footer></m-footer>
  </div>
</template>

<script>
import MFooter from "@/components/mb_footer";
import MShowdown from "@/components/showdown";

export default {
  components: { MFooter, MShowdown },
  data() {
    return {
      msg: "helloin",
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mb_produce_main {
  width: 100%;
  position: relative;

  .mb_produce_imgbg {
    width: 100%;
  }

  .goback {
    position: absolute;
    width: 1.8rem;
    height: 0.8rem;
    top: 0.2rem;
    left: 0.4rem;
  }
}
</style>
