<template>
  <div>
    <div v-show="showLogin">
      <div class="mask" @click="closeLogin"></div>
      <div class="mb_login">
        <div class="close_pop" @click="closeLogin"></div>
        <div class="login-bg">
          <div class="login-item">
            <label class="login-title">登入帳號</label>
            <input type="text" v-model="loginData.account" placeholder="Enter 6~18 characters or number" minlength="6"
                   maxlength="18"/>
          </div>
          <div class="login-item">
            <label class="login-title">密碼</label>
            <input type="password" v-model="loginData.password" placeholder="Enter 6~18 characters or number"
                   minlength="6" maxlength="18"/>
          </div>
          <div class="login_btn" @click="handleLogin"></div>
        </div>
        <div class="third-part">
          <div @click="handleGoogle">
            <img class="gp" src="../assets/img/login/l-gp.png" alt/>
          </div>
          <FacebookLogin v-bind="$attrs" @fbLogin="fbLogin" :appid="appidiOS">
            <img src="../assets/img/login/IOS-FB.png" alt="" />
          </FacebookLogin>
          <FacebookLogin v-bind="$attrs" @fbLogin="fbLogin" :appid="appidandro">
            <img src="../assets/img/login/AND-FB.png" alt="" />
          </FacebookLogin>
        </div>
      </div>
    </div>
    <div class="mask" @click="[showLoginSuccess = false, showLoginFailed=false]"
         v-if="showLoginSuccess || showLoginFailed"></div>
    <transition name="fade">
      <div v-if="showLoginSuccess" class="mb_login">
        <div class="close_pop result-top" @click="showLoginSuccess = false"></div>
        <img class="success-pop" alt src="../assets/img/successPop.png"/>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showLoginFailed" class="mb_login">
        <div class="close_pop result-top" @click="closeLoginFailed"></div>
        <img class="failed-pop" alt src="../assets/img/login/failedbg.png"/>
        <p>{{ login_error }}</p>
      </div>
    </transition>
  </div>
</template>
<script>
import '../assets/css/mbCommon.scss';
import FacebookLogin from "./thirdLogin/FacebookLogin.vue";
import {validateNum} from "../utils/utils";
import store from '../store/index';
import app from "../App.vue";
import axios from "axios";

export default {
  computed: {
    app() {
      return app
    }
  },
  components: {FacebookLogin},
  name: 'MLogin',
  props: {
    showLoginModal: {
      type: Boolean
    },
    thirdParams: {
      type: Function
    }
  },
  watch: {
    showLoginModal: {
      immediate: true,
      handler(newVal) {
        this.showLogin = newVal
      }
    }
  },
  data() {
    return {
      appidiOS: '',
      appidandro: '',
      showLoginSuccess: false,
      showLoginFailed: false,
      login_error: '登录失败',
      loginData: {
        account: '',
        password: '',
        type: 'MBean'
      },
      app_id: "0xlnxs",
      showLogin: false,
    }
  },
  created() {
    this.appidiOS =  '510185113529844'
    this.appidandro = '315647499309742'
  },
  methods: {
    closeLoginFailed(){
      this.showLoginFailed = false
      window.location.href = `https://home.lukycube.com/#` + this.$route.path
    },
    closeLogin() {
      this.$emit('closeLogin')
    },
    handleLogin() {
      if (!this.loginData.account || !this.loginData.password) {
        this.$layer.msg('請填寫帳號和密碼')
        return
      }
      // 正則
      if (!validateNum(this.loginData.account) || !validateNum(this.loginData.password)) {
        this.$layer.msg('請填寫6-18字元的帳號或密碼')
        return
      }
      let params = {
        account: this.loginData.account,
        password: this.loginData.password,
        type: 'MBean'
      }
      this.confirmLogin(params)
    },
    showLoginResult(val) {
      this.showLoginSuccess = val
      this.showLoginFailed = !this.showLoginSuccess
    },
    confirmLogin(params) {
      let _this = this
      this.postHttp(
          this,
          params,
          "/login",
          function (obj, res) {
            const {code, data, message} = res
            if (code === 200) {
              store.commit('setPkUserInfo', data)
              _this.getNickName(data?.uuid)
              _this.showLoginResult(true)
              _this.$emit('closeLogin')
            } else {
              _this.login_error = message
              _this.showLoginResult(false)
            }
          }
      );
    },
    async getNickName(uuid){
      await axios.get('https://dpk.lukycube.com:8198/MF_USERINFO.asmx/USERINFO?UUID=' + uuid).then((response) => {
        const xml = new DOMParser().parseFromString(response.data, 'text/xml');
        if (xml) {
          var json = JSON.parse(xml.documentElement.textContent);
          var listValue = json.Rows;
          store.commit('setAccount',listValue[0][0])

        }
      }).catch((res) => {

      })
    },
    fbLogin(params) {
      this.confirmLogin(params)
    },
    handleGoogle() {
      window.location.href =
          "https://sdk.gamemorefun.com/rest/v2/passport/google/5?app_id=" +
          this.app_id +
          "&redirect_url=https%3A%2F%2Fhome.lukycube.com%2F%23%2F" + window.location.hash.substring(2, window.location.hash.length);
    },
    handleFb() {
      window.location.href =
          "https://sdk.gamemorefun.com/mobile/v1/passport/facebook/5?app_id=" +
          this.app_id +
          "&redirect_url=https%3A%2F%2Fhome.lukycube.com%2F%23%2F" + window.location.hash.substring(2, window.location.hash.length);
    }
  }
}
</script>
<style lang="scss" scoped>
$background-size: 100% 100%;
.gp {
  width: 2.5rem;
  margin-top: 0.1rem;
}

.third-part {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width:7.5rem;
  align-items: center;
  margin-left: 1.23rem;
  margin-top: -4.8rem;
  img {
    width: 100%;
    margin-top: 0.3rem;
  }
}

.login-bg {
  background: url("../assets/img/login/loginBg.png") no-repeat;
  background-size: 100% 100%;
  width: 9rem;
  height: 13rem;
  margin-left: 0.3rem;
}

.login-item {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 1.8rem;
  padding: 0 0.88rem;

  input {
    width: 7.5rem;
    height: 1rem;
    margin-top: 0.1rem;
    border-radius: 0.2rem;
    padding-left: 0.15rem;
  }
}

.login-item:nth-of-type(2) {
  margin-top: 0.25rem;
}

.login-title {
  font-size: 0.5rem;
  color: #fae02d;
  text-align: left;
  margin-left: 0.2rem;
}

.login_btn {
  background: url("../assets/img/login/loginBtn.png") no-repeat;
  background-size: 100% 100%;
  width: 7.5rem;
  height: 1rem;
  position: relative;
  left: 0.88rem;
  top: 2.55rem;
}
.FBbtn {
  width: 2.5rem;
  margin-top: 0.1rem;
}
</style>