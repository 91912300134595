export const proList = [
    {
        id: 'com.qp.luckycen1',
        price: 0.99,
        proSrc: 'pro01'
    },
    {
        id: 'com.qp.luckycen2',
        price: 1.99,
        proSrc: 'pro02'
    },
    {
        id: 'com.qp.luckycen3',
        price: 4.99,
        proSrc: 'pro03'
    },
    {
        id: 'com.qp.luckycen4',
        price: 9.99,
        proSrc: 'pro04'
    },
    {
        id: 'com.qp.luckycen5',
        price: 19.99,
        proSrc: 'pro05'
    },
    {
        id: 'com.qp.luckycen6',
        price: 29.99,
        proSrc: 'pro06'
    },
    {
        id: 'com.qp.luckycen7',
        price: 49.99,
        proSrc: 'pro07'
    },
    {
        id: 'com.qp.luckycen8',
        price: 99.99,
        proSrc: 'pro08'
    }
]