<!--
 * @Descripttion:
 * @version:
 * @Author: liy
 * @Date: 2023-03-29 18:07:44
 * @LastEditTime: 2023-03-29 18:08:04
-->
<template>
  <div class="pc_charge_center">
    <b-header></b-header>
    <img class="bg" src="../assets/img/pc_new/charge_center_bg.png" alt="" />
    <img class="logo" src="../assets/img/pc_new/charge_center_logo.png" alt="" />
    <ul>
      <li v-for="(item, index) in goodsList" :key="index" class="flexCloumn">
        <img :class="'img' + index" :src="item.good_img" alt="" />
        <span>{{ item.good_name }}</span>
        <span>{{ item.good_extra }}</span>
        <span @click="goCharge(item)">US ${{ item.good_price }}</span>
      </li>
    </ul>
    <PcPopup
      v-if="showChargeSuccess || showChargeFailed"
      :showChargeSuccess="showChargeSuccess"
      :showChargeFailed="showChargeFailed"
      @closeChargePopUp="closeChargePopUp"
    />
    <Footer/>
  </div>
</template>
<script>
import BHeader from "@/components/head";
import store from "../pcStore.js";
import PcPopup from "@/components/pcPopup.vue";
import Footer from "@/components/footer";
import { getHashSearchParam } from "../utils/common";
export default {
  components: { BHeader, PcPopup, Footer },
  data() {
    return {
      goodsList: [
        {
          good_img: this.getImgUrl("p1.png"),
          good_name: "30,000",
          good_extra: "+3,000",
          good_price: "0.99",
          product_id: "com.qp.luckycen1",
        },
        {
          good_img: this.getImgUrl("p2.png"),
          good_name: "63,000",
          good_extra: "+6,300",
          good_price: "1.99",
          product_id: "com.qp.luckycen2",
        },
        {
          good_img: this.getImgUrl("p3.png"),
          good_name: "162,000",
          good_extra: "+16,200",
          good_price: "4.99",
          product_id: "com.qp.luckycen3",
        },
        {
          good_img: this.getImgUrl("p4.png"),
          good_name: "330,000",
          good_extra: "+33,000",
          good_price: "9.99",
          product_id: "com.qp.luckycen4",
        },
        {
          good_img: this.getImgUrl("p5.png"),
          good_name: "675,000",
          good_extra: "+67,500",
          good_price: "19.99",
          product_id: "com.qp.luckycen5",
        },
        {
          good_img: this.getImgUrl("p6.png"),
          good_name: "1,050,000",
          good_extra: "+105,000",
          good_price: "29.99",
          product_id: "com.qp.luckycen6",
        },
        {
          good_img: this.getImgUrl("p7.png"),
          good_name: "1,800,000",
          good_extra: "+180,000",
          good_price: "49.99",
          product_id: "com.qp.luckycen7",
        },
        {
          good_img: this.getImgUrl("p8.png"),
          good_name: "3,675,000",
          good_extra: "+367,500",
          good_price: "99.99",
          product_id: "com.qp.luckycen8",
        },
      ],
      showChargeSuccess: false,
      showChargeFailed: false,
    };
  },
  created() {
    this.getPayStatus();
  },
  methods: {
    getImgUrl(name) {
      return require(`../assets/img/pc_new/${name}`);
    },
    goCharge(item) {
      let token = store.state.pk_userInfo?.token || "";

      if (token) {
        // 去储值
        let uuid = store.state.pk_userInfo.uuid;
        const app_id = "0xlnxs";
        const product_id = item.product_id;
        const extInfo = "webPay";
        const redirect_url = "https%3A%2F%2Fhome.lukycube.com%2F%23%2FchargeCenter";
        const newLink = `https://lukycube.com/webapi/payments?token=${token}&app_id=${app_id}&uuid=${uuid}&product_id=${product_id}&extInfo=${extInfo}&device_type=5&redirect_url=${redirect_url}`;
        location.href = newLink;
      } else {
        // 去登录
        store.commit("showLogin", true);
      }
    },
    getPayStatus() {
      let pay_state = getHashSearchParam("pay_state");
      pay_state = parseInt(pay_state);
      if (pay_state === 1) {
        this.showChargeSuccess = true;
      } else if (pay_state === 0) {
        this.showChargeFailed = true;
      }
    },
    closeChargePopUp() {
      this.showChargeSuccess = false;
      this.showChargeFailed = false;
      // https://home.lukycube.com/#/chargeCenter
      window.location.href = "https://home.lukycube.com/#/chargeCenter";
    },
  },
};
</script>
<style lang="scss" scoped>
$textColor1: #ffd11a;
$textColor2: #5464ff;
$textColor3: #a06b17;
.pc_charge_center {
  position: relative;
  .bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
  }
  .logo {
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4.3rem;
  }
  ul {
    width: 63%;
    display: grid;
    grid-template-rows: 4.03rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    position: absolute;
    top: 6.8rem;
    left: calc(50% - 31%);
    li {
      position: relative;
      width: 2.9rem;
      height: 4.03rem;
      background: url("../assets/img/pc_new/good_BG.png") no-repeat;
      background-size: 100%;
      img {
        margin: -0.4rem auto 0.2667rem;
      }
      .img0 {
        width: 1.6rem;
      }
      .img1 {
        width: 1.7rem;
      }
      .img2 {
        width: 1.8rem;
      }
      .img3 {
        width: 1.9rem;
      }
      .img4 {
        width: 2rem;
      }
      .img5 {
        width: 2.1rem;
      }
      .img6 {
        width: 2.2rem;
      }
      .img7 {
        width: 2.3rem;
      }
      span {
        font-weight: bold;
      }
      span:nth-of-type(1) {
        color: $textColor1;
        font-size: 0.4rem;
      }
      span:nth-of-type(2) {
        color: $textColor2;
        font-size: 0.3rem;
      }
      span:nth-of-type(3) {
        position: absolute;
        bottom: 0.1rem;
        width: 100%;
        line-height: 0.6rem;
        color: $textColor3;

        font-size: 0.2rem;

        cursor: pointer;
      }
    }
  }
}
.pc_charge_center:after {
  content: '';
  display: block;
  clear: both;
}
</style>
