/*
 * @Descripttion: 
 * @version: 
 * @Author: liy
 * @Date: 2023-04-03 09:58:48
 * @LastEditTime: 2023-04-14 11:32:37
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    pk_userInfo: JSON.parse(localStorage.getItem("pk_userInfo")) || null,
    nickName:JSON.parse(localStorage.getItem('pk_account')) || '',
    showLogin: false,
    show_account_cancel: false,
  },
  mutations: {
    // 存储用户信息
    setUserInfo(state, userInfo) {
      state.pk_userInfo = userInfo;
     if(userInfo) {
      localStorage.setItem("pk_userInfo", JSON.stringify(userInfo));
     }
    },
    setNickName(state, nickName) {
      state.nickName = nickName;
      if(nickName) {
        localStorage.setItem("pk_account", JSON.stringify(nickName));
      }
    },
    // 登出
    logOut(state) {
      state.pk_userInfo = null;
      state.nickName = '';
      localStorage.removeItem("pk_userInfo");
      localStorage.removeItem('pk_account');
      location.reload()
    },
    //显示登录框
    showLogin(state, payload) {
      state.showLogin = payload;
    },
    setAccountDeletion(state, payload){
      state.show_account_cancel = payload
    }
  },
  actions: {},
  getters: {
    
  },
});
