<template>
  <div class="mb_app_teach">
    <go-back></go-back>
    <m-showdown></m-showdown>
    <img src="../assets/img/appteach_mb.png" alt class="teach" />
    <!--    <a :href="applink">-->
    <!--      <img src="../assets/img/app.png" alt class="appdown" />-->
    <!--    </a>-->
    <!--    <a :href="googlelink">-->
    <!--      <img src="../assets/img/google.png" alt class="googledown" />-->
    <!--    </a>-->
    <!--    <a :href="mycardlink">-->
    <!--      <img src="../assets/img/mycard.png" alt class="mycard" />-->
    <!--    </a>-->
    <m-footer></m-footer>
  </div>
</template>

<script>
import MFooter from "@/components/mb_footer";
import MShowdown from "@/components/showdown";
import GoBack from "./goBack.vue";
export default {
  components: { MFooter, MShowdown, GoBack },
  data() {
    return {
      googleLink: "https://play.google.com/store/apps/details?id=com.qp.dpk",
      applink: "",
      mycardlink: "",
    };
  },
  mounted: function () {
    //下載鏈接
    this.getHttp(this, "", "/link", function (obj, data) {
      obj.googleLink = data.google;
      obj.applink = data.apple;
      obj.mycardlink = data.mycard;
    });
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scope>
.mb_app_teach {
  width: 100%;
  position: relative;
  .teach {
    width: 100%;
  }
  .goback {
    position: absolute;
    width: 1rem;
    height: 1rem;
    bottom: 1.5rem;
    left: 50%;
    margin-left: -0.5rem;
  }
  .appdown,
  .googledown,
  .mycard {
    position: absolute;
    width: 2.4rem;
    top: 3.3rem;
    left: 50%;
    cursor: pointer;
    transition: all 0.4s ease-in;
  }
  .appdown:hover,
  .googledown:hover,
  .mycard:hover {
    transform: scale(1.05);
  }
  .appdown {
    margin-left: -4rem;
  }
  .googledown {
    margin-left: -1.2rem;
  }
  .mycard {
    margin-left: 1.65rem;
  }
}
</style>
