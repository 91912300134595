<template>
  <div class="mb_index_main">
    <m-showdown></m-showdown>
    <picture>
      <source srcset="../assets/img/mb_index_bg.webp" type="image/webp" />
      <source srcset="../assets/img/mb_index_bg.png" type="image/png" />
      <img src="../assets/img/mb_index_bg.png" alt="Image" class="mb_index_imgbg" />
    </picture>
    <div class="nav">
      <div
        v-show="!user.uuid"
        class="nav-user-account left20"
        @click="showLoginMenu = !showLoginMenu"
      ></div>

      <!--      <img alt v-show="!user.uuid" class="nav-loginBtn" src="../assets/img/login/loginSmall.png" />-->
      <div v-show="user.uuid" class="inline-block">
        <div class="nav-user-account" @click="showLoginMenu = !showLoginMenu">
          <span>{{ user.user_name }}</span>
        </div>
        <img
          alt
          class="nav-loginBtn"
          src="../assets/img/login/loginOut.png"
          @click="handleOut"
        />
      </div>
      <!--      <img class="showpic2" src="../assets/img/topicon.png" />-->
      <img class="gotonav" src="../assets/img/meau.png" @click="showmtips(true)" />
    </div>
    <div class="mask" v-show="showLoginMenu"></div>
    <div class="login-menu" v-show="showLoginMenu">
      <span @click="showLoginMenu = false">X</span>
      <div v-if="user" @click="handleLogout">登出</div>
      <div v-else @click="handleLogin">登入</div>
      <div @click="showAccountDeletion">註銷帳號</div>
    </div>

    <div class="code_box">
      <img :src="fuli" alt />
      <p class="code_des">掃我領好康</p>
    </div>
    <a :href="appLine" target="_blank">
      <img src="../assets/img/app.png" alt class="appdown" />
    </a>
    <!-- :href="googleLink" -->
    <a href="javascript:;" @click="tipDialog = true">
      <img src="../assets/img/google.webp" alt class="googledown" />
    </a>
    <a :href="mycardLink" target="_blank">
      <img src="../assets/img/mycard.png" alt class="mycard" />
    </a>
    <div v-show="bannerList.length > 0" class="swiper-container swiper-container1">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(url, index) in bannerList" :key="index">
          <a :href="url.link" target="_blank">
            <img :src="url.images" referrerpolicy="no-referrer" />
          </a>
        </div>
        <!-- <div class="swiper-slide" >
          <img src="../assets/img/banner.png" />
        </div>
        <div class="swiper-slide" >
          <img src="../assets/img/banner.png" />
        </div>   -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
    <div class="new_list">
      <div class="list_head">
        <div
          :class="[activeNew == 1 ? 'head_item active' : 'head_item']"
          v-on:click="chageList(1)"
        >
          遊戲公告
        </div>
        <div class="line"></div>
        <div
          :class="[activeNew == 2 ? 'head_item active' : 'head_item']"
          v-on:click="chageList(2)"
        >
          活動消息
        </div>
        <div class="line"></div>
        <!--        <a href="https://richlist.lukycube.com/" target="_blank">-->
        <div class="head_item" @click="getNews">實時排行榜</div>
        <!--        </a>-->
        <div class="line"></div>
        <router-link to="/mbnewlist">
          <div class="head_item">更多+</div>
        </router-link>
      </div>
      <div class="list_detail">
        <div
          class="detail_item"
          v-for="(item, index) in massageList"
          :key="index"
          @click="gotoDeatil(item.id)"
        >
          <div class="titles">{{ item.message_title }}</div>
          <div class="time">{{ item.uptime }}</div>
        </div>
      </div>
    </div>
    <div
      class="mask"
      @click="[(showChargeSuccess = false), (showChargeFailed = false)]"
      v-if="showChargeSuccess || showChargeFailed || tipDialog"
    ></div>
    <transition name="right">
      <m-tips @mbtipsout="showmtips" v-if="mbtipson"></m-tips>
    </transition>
    <transition name="fade">
      <div v-if="showChargeSuccess" class="mb_login">
        <div class="close_pop result-top" @click="closeChargeResult"></div>
        <img class="success-pop" alt src="../assets/img/successCharge.png" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showChargeFailed" class="mb_login">
        <div class="close_pop result-top" @click="showChargeFailed = false"></div>
        <img class="failed-pop" alt src="../assets/img/failedCharge.png" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="tipDialog" class="tipDialogBg">
        <img
          @click="tipDialog = false"
          class="mb_x"
          src="../assets/img/mb_x.png"
          alt=""
        />

        <p class="tipDialog_text">
          尚未開放 <br />
          敬請期待
        </p>
      </div>
    </transition>
    <m-login @closeLogin="closeLogin" :showLoginModal="showLoginModal" ref="mLogin" />
    <m-footer></m-footer>
    <mb-cancel-account />
  </div>
</template>

<script>
import MFooter from "@/components/mb_footer";
import MTips from "@/components/mbtips";
import MShowdown from "@/components/showdown";
import MLogin from "@/components/mb_login";
import MbCancelAccount from "@/views/accountCancel/mbCancelAccount.vue";
import "../assets/css/mbCommon.scss";
import store from "../store/index";
import Swiper from "swiper";
import { getQueryString } from "../utils/utils";
import axios from "axios";
import { getHashSearchParam } from "../utils/common";

export default {
  components: { MFooter, MTips, MShowdown, MLogin, MbCancelAccount },
  data() {
    return {
      tipDialog: false,
      showLoginSuccess: false,
      showLoginFailed: false,
      login_error: "登录失败",
      user: {
        user_name: "",
      },
      fuli: "",
      googleLink: "https://play.google.com/store/apps/details?id=com.qp.dpk",
      appLine: "",
      activeNew: 1,
      massageList: [],
      bannerList: [],
      mbtipson: false,
      mycardLink: "",
      showLoginMenu: false,
      showLoginModal: false,
      showChargeSuccess: false,
      showChargeFailed: false,
    };
  },
  created() {},
  mounted() {
    this.thirdParams();
    this.getPayStatus();
    this.user = JSON.parse(localStorage.getItem("pk_userInfo")) ?? "";
    var swiper = new Swiper(".swiper-container1", {
      spaceBetween: 0,
      autoplay: true,
      observer: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    //二維碼
    this.getHttp(this, "", "/code", function (obj, data) {
      obj.fuli = data.fuli;
    });
    //banner
    this.getHttp(this, "", "/banner", function (obj, data) {
      for (var i in data) {
        obj.bannerList.push(data[i]);
      }
    });

    //下載鏈接
    this.getHttp(this, "", "/link", function (obj, data) {
      obj.googleLink = data.google;
      obj.appLine = data.apple;
      obj.mycardLink = data.mycard;
    });
    //游戏公告
    this.changeNew(1);
  },
  methods: {
    showAccountDeletion() {
      this.showLoginMenu = false;
      if (!this.user.user_name) {
        this.showLoginModal = true;
      } else {
        store.commit("setAccountDeletion", true);
      }
    },
    showLoginResult(val) {
      this.showLoginSuccess = val;
      this.showLoginFailed = !this.showLoginSuccess;
    },
    confirmLogin(params) {
      let _this = this;
      this.postHttp(this, params, "/login", function (obj, res) {
        const { code, data, message } = res;
        if (code === 200) {
          store.commit("setPkUserInfo", data);
          _this.showLoginResult(true);
        } else {
          _this.login_error = message;
          _this.showLoginResult(false);
        }
      });
    },
    parse(xmlString) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "text/xml");
      const rowsValue = xmlDoc.getElementsByTagName("string")[0].textContent;
      return JSON.parse(rowsValue);
    },
    getNews() {
      let info = JSON.parse(localStorage.getItem("pk_userInfo"));
      let uuid, xmlObject, params, url;
      if (info && info?.uuid) {
        uuid = info.uuid;
        axios
          .get(`https://dpk.lukycube.com:8198/MF_USERINFO.asmx/USERINFO?UUID=${uuid}`)
          .then((res) => {
            if (res.status === 200) {
              xmlObject = this.parse(res.data);
              params = xmlObject?.Rows[0];
              url =
                "https://richlist.lukycube.com/?USERID=" +
                params[1] +
                "&NICKNAME=" +
                params[0] +
                "&IMG=" +
                `https://graph.facebook.com/${params[1]}/picture?width=80&height=80`;
              window.location.href = url;
            } else {
              console.log(res.data);
            }
          });
      } else {
        uuid = "";
        window.location.href =
          "https://richlist.lukycube.com/?USERID=" + "&NICKNAME=" + "&IMG=";
      }
    },
    closeChargeResult() {
      this.showChargeSuccess = false;
      window.location = "https://home.lukycube.com/#/mbCharge";
    },
    getPayStatus() {
      let pay_state = getQueryString("pay_state");
      pay_state = parseInt(pay_state);
      if (pay_state === 1) {
        this.showChargeSuccess = true;
      } else if (pay_state === 0) {
        this.showChargeFailed = true;
      }
    },
    handleLogin() {
      this.showLoginMenu = false;
      this.showLoginModal = true;
    },
    handleLogout() {
      store.commit("logOut");
    },
    closeLogin() {
      this.showLoginModal = false;
    },
    handleOut() {
      store.commit("logOut");
      window.location.href = "https://home.lukycube.com/#/mbindex";
    },
    showmtips(e) {
      this.mbtipson = e;
    },
    goback() {
      this.$router.go(-1);
    },
    gotoDeatil(e) {
      let obj_message = this.massageList.filter((it) => {
        return it.id === e;
      });
      if (obj_message[0].message_link) {
        window.open(obj_message[0].message_link);
      } else {
        this.$router.push({ path: "/mbnewdetail", query: { articleId: e } });
      }
    },
    changeNew(e) {
      let datas = {
        num: 6,
        page: 1,
        type: e,
      };
      this.massageList = [];
      this.getHttp(this, datas, "/message", function (obj, data) {
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      });
    },
    chageList(a) {
      this.activeNew = a;
      this.changeNew(a);
    },
    async thirdParams() {
      let params;
      let account = getHashSearchParam("account");
      let type = getHashSearchParam("type");
      if (type === "google") {
        params = {
          account: account.substring(3, account.length),
          password: "",
          type: "Google",
        };
        this.thirdLogin(params);
      }
    },
    thirdLogin(params) {
      this.$refs.mLogin.confirmLogin(params);
    },
  },
  computed: {
    userInfo() {
      return store?.state?.userInfo ?? {};
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    account() {
      return JSON.parse(localStorage.getItem("pk_account"));
    },
  },

  watch: {
    userInfo(newV, oldV) {
      this.user = newV;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$url: "../assets/img/login/";
$background-size: 100% 100%;
.mb_x {
  position: absolute;
  right: -0.2rem;
  top: -0.4rem;
  width: 1.2rem;
}
.tipDialog_text {
  margin-top: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.08rem;
  color: #fff;
  font-size: 0.96rem;
}
.right-enter,
.right-leave-to {
  transform: translate3d(100%, 0, 0);
}

.right-leave,
.right-enter-to {
  transform: translate3d(0, 0, 0);
}

.right-enter-active,
.right-leave-active {
  transition: all 0.5s;
}

.mb_index_main {
  position: relative;
  width: 100%;

  .mb_index_imgbg {
    width: 100%;
  }

  .code_box {
    position: absolute;
    width: 2.8rem;
    height: 2.6rem;
    top: 12.5rem;
    left: 50%;
    margin-left: -1.34rem;

    img {
      width: 93%;
    }
    .code_des {
      margin-top: 0.37rem;
      font-size: 0.49rem;
      letter-spacing: 0.065rem;
      font-weight: 100;
      color: #fff;
    }
  }

  .appdown,
  .googledown,
  .mycard {
    position: absolute;
    width: 3rem;
    top: 16.2rem;
    left: 50%;
    cursor: pointer;
  }

  .mycard {
    margin-left: 1.7rem;
  }

  .googledown {
    margin-left: -1.48rem;
  }

  .appdown {
    margin-left: -4.7rem;
  }

  .showpic2 {
    position: absolute;
    z-index: 10;
    width: 4.5rem;
    top: 0.133rem;
    left: 0.187rem;
  }
  .login-menu {
    z-index: 1000;
    color: white;
    width: 9rem;
    border-radius: 0.2rem;
    margin: auto;
    left: calc((100vw - 9rem) / 2);
    top: 30vh;
    position: absolute;
    background: white;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 0.4rem;
      position: relative;
      top: -0.6rem;
      left: 3.7rem;
      color: black;
    }

    div {
      margin-top: 0.2rem;
      width: 85%;
      font-size: 0.4rem;
      height: 1rem;
      line-height: 1rem;
      background: #2e2e2e;
      border-radius: 0.2rem;
    }
    div:active {
      opacity: 0.8;
    }
  }
  .nav {
    flex-wrap: nowrap;
    display: flex;
    position: absolute;
    width: 100%;
    height: 1.333rem;
    top: 0;
    z-index: 9;
    background-color: rgba($color: #1c1436, $alpha: 0.74);
    border-bottom: 1px solid #ffffff;

    .el-icon-user-solid {
      color: #f08200;
      font-size: 30px;
    }
    .gotonav {
      width: 1rem;
      height: 0.7rem;
      float: right;
      margin-top: 0.27rem;
      margin-right: 0.4rem;
      position: relative;
    }

    .nav-loginBtn {
      width: 2rem;
      height: 0.85rem;
      margin-top: calc((1.33rem - 0.85rem) / 2);
      margin-left: 5rem;
      position: relative;
    }

    .nav-user-account {
      background: #1c1436;
      height: 0.88rem;
      //width: 5.2rem;
      border-radius: 0.2rem;
      margin-top: calc((1.33rem - 0.88rem) / 2);
      position: absolute;
      margin-left: 1.8rem;
      font-size: 0.34rem;

      span {
        color: white;
        position: relative;
        top: -0.55rem;
        left: 1rem;
        width: 1.8rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
    }

    .nav-user-account::before {
      content: "";
      background: url($url + "avatar.png") no-repeat;
      background-size: $background-size;
      width: 0.8rem;
      height: 0.8rem;
      position: relative;
      display: block;
      margin-top: 0.01rem;
      margin-left: 0.2rem;
    }
  }

  .swiper-container1 {
    position: absolute;
    width: 8.6rem;
    top: 18.3rem;
    left: 50%;
    margin-left: -4.3rem;
    height: 5.7rem;

    img {
      width: 100%;
    }
  }

  .new_list {
    width: 8.6rem;
    position: absolute;
    left: 50%;
    margin-left: -4.3rem;
    top: 24.8rem;

    .list_head {
      width: 100%;
      height: 1rem;
      background-color: #577fe4;
      color: white;
      padding-left: 0.15rem;

      .line {
        height: 0.3rem;
        width: 1px;
        background-color: white;
        float: left;
        margin-top: 0.3rem;
      }

      .head_item {
        float: left;
        line-height: 0.95rem;
        padding: 0 0.25rem;
        cursor: pointer;
        font-size: 0.4rem;
        color: white;
      }

      .head_item:hover {
        color: #fff260;
      }

      .active {
        color: #fff260;
        border-bottom: 2px solid #fff260;
      }

      .head_item:last-child {
        border-right: 0;
      }
    }

    .list_item {
      width: 100%;
      height: 6rem;
      overflow: hidden;
    }

    .detail_item:hover {
      .titles,
      .time {
        color: #fff260;
      }
    }

    .list_detail {
      line-height: 1.25rem;
      color: white;
      font-size: 0.35rem;
      padding: 0.1rem 0.3rem;
      width: 100%;
      float: left;
      height: 7.8rem;
      background: url(../assets/img/newbg.png) no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      cursor: pointer;

      .titles {
        width: 75%;
        float: left;
        text-align: left;
        border-bottom: 1px solid white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .time {
        width: 25%;
        float: left;
        border-bottom: 1px solid white;
      }
    }
  }
}
.left20 {
  position: relative;
  left: 5rem;
}
.inline-block {
  display: inline-block;
}
</style>
