<template>
  <div class="produce_main">
    <b-header></b-header>
    <img src="../assets/img/produce_list.png" alt class="bgimg" />
    <img src="../assets/img/logo.png" alt="" class="logo" />
    <div class="pro_list">
      <div class="pro_list_item" v-for="(item, index) in gameList" :key="index">
        <img :src="item.game_img" alt class="pro_list_img" />
        <div class="game_name">{{ item.name }}</div>
        <div class="game_detail">{{ item.intruduce }}</div>
      </div>
    </div>
    <b-footer></b-footer>
  </div>
</template>

<script>
import zspk from "../assets/img/dzpk.png";
import niuniu from "../assets/img/niuniu.png";
import dl2 from "../assets/img/dl2.png";
import ssz from "../assets/img/ssz.png";
import d21 from "../assets/img/21.png";
import gdmj from "../assets/img/gdmj.png";
import twmj from "../assets/img/twmj.png";
import hhps from "../assets/img/hhps.png";
import ddz from "../assets/img/ddz.png";
import brnn from "../assets/img/brnn.png";
import fksc from "../assets/img/fksc.png";
import chao8 from "../assets/img/chao8.png";
import slz from "../assets/img/slz.png";
import BHeader from "@/components/head";
import BFooter from "@/components/footer";
export default {
  components: { BHeader, BFooter },
  data() {
    return {
      gameList: [
        {
          id: 1,
          name: "德州撲克",
          game_img: zspk,
          intruduce:
            "德州撲克規則類似於五張，主要區別在於，玩家有兩張暗牌和五張公共牌，用這七張牌任意組合出的五張手牌最大的玩家贏得該局比賽。",
        },
        {
          id: 2,
          name: "廣東麻將",
          game_img: gdmj,
          intruduce:
            "廣東麻將的規則體系發展歷史源遠流長，如今已經很難考證。據了解，廣東地區流行的主要規則便是雞平胡、推倒胡、新章、老章這四大派系。而雞平胡規則是廣東珠三角地區普及最廣、最為流行的打法，講究雞胡、平胡、爆胡，打法簡單節奏快速。",
        },

        {
          id: 3,
          name: "百人妞妞",
          game_img: brnn,
          intruduce:
            "百人妞妞是在妞妞基礎上拓展的全新玩法，該遊戲由莊家與閒家一起玩一副牌，遊戲用牌為一副牌52張去除大小王。每局有一個莊家，其餘為閒家，每人五張發完牌後即可開牌比牌，閒家分別與莊家比較牌型大小定勝負。",
        },
        {
          id: 4,
          name: "妞妞",
          game_img: niuniu,
          intruduce:
            "2-4人遊戲，一副牌進行，每人發5張牌。當4個人都開始遊戲後，直接每人發5張牌組合牌型。使用者可以使用自動攤牌，系統會按照手牌的最大點數給予自動配牌。",
        },
        {
          id: 5,
          name: "超8",
          game_img: chao8,
          intruduce:
            "超8是一種slots遊戲，經常可在賭場或者專設角子機的娛樂場所見到。玩法是將「角子」（意為硬幣）投入機器，接著機器螢幕上會隨機滾動出現不同圖案，停定時如出現符合相同或特定相同圖案連線者，即依其賠率勝出。如入虎口，亦稱吃角子老虎機或老虎機。由於早期的機台側面有一條拉杆，所以也被稱作拉霸。",
        },
        {
          id: 6,
          name: "二十一點",
          game_img: d21,
          intruduce:
            "21點又名BlackJack，由2到5個人玩，使用4副撲克除大小王之外的208張牌，遊戲者的目標是手中的牌的點數之和不超過21點，且盡量最大。所有閒家都與莊家比較。黑傑克為特殊牌型，比其他所有牌型都大。除黑傑克外，其他牌型都以點數比較大小。",
        },
        {
          id: 7,
          name: "鬥地主",
          game_img: ddz,
          intruduce:
            "鬥地主，是一種在中國流行的紙牌遊戲。遊戲最少由3個玩家進行，用一副54張牌（連鬼牌），其中一方為地主，其余兩家為另一方，雙方對戰，先出完牌的一方獲勝。該撲克遊戲最初流行於中國湖北武漢市漢陽區，現已逐漸在全球各地流行。",
        },
        {
          id: 8,
          name: "十六張麻將",
          game_img: slz,
          intruduce:
            "十六張麻將是臺灣常見的十六張麻雀玩法，計分上以番數計算。因取牌16張，亦稱為臺灣16張麻雀。",
        },
        {
          id: 9,
          name: "大老二",
          game_img: dl2,
          intruduce:
            "一開始每個玩家都會拿到 13 張牌，拿到方塊3的人可以優先出牌，妳可以選擇打煉單、對子、三張、五張順子、同花、葫蘆、鐵支、同花順等牌型。",
        },
        {
          id: 10,
          name: "十三支",
          game_img: ssz,
          intruduce:
            "每位玩家需將手上的十三張牌分為三副牌，第一副牌三張，第二，三副牌各五張，比牌方式為先比牌型，大者為勝，如為同一牌型，則比所持牌張點數大小，先比該副牌牌中最大的一隻，如又有相同時，再比第二隻，以此類推。",
        },
        {
          id: 11,
          name: "瘋狂賽車",
          game_img: fksc,
          intruduce:
            "由4款賽車車隊分為大小隊，組成8個區域。可選擇一個或多個區域贊助車隊。勝利後，小車隊為5倍金幣。大車隊分別為：保時捷40倍金幣，寶馬30倍金幣，賓士20倍金幣，大眾10倍金幣。",
        },
      ],
    };
  },
  mounted: function () {},
  methods: {},
};
</script>
<style lang="scss" scope>
.produce_main {
  width: 100%;
  position: relative;
  .bgimg {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 1.6rem;
    left: calc(50% - 2rem);
    width: 4rem;
  }
  .pro_list {
    width: 13rem;
    position: absolute;
    top: 5.7rem;
    left: 50%;
    margin-left: -6rem;
    .pro_list_item {
      float: left;
      width: 6.2rem;
      height: 3rem;
      background: url(../assets/img/pro_list_bg.png) no-repeat;
      background-size: 100% 100%;
      margin: 0.1rem;
      color: white;
      font-size: 0.3rem;
      position: relative;
      cursor: default;
      img {
        width: 2.1rem;
        position: absolute;
        right: 3.9rem;
        top: calc(50% - 1.1rem);
        transition: all 0.2s ease-in;
      }
      .game_name {
        float: right;
        width: 3.8rem;
        line-height: 0.8rem;
        text-align: left;
      }
      .game_detail {
        float: right;
        width: 3.8rem;
        line-height: 0.3rem;
        text-align: left;
        padding-right: 0.2rem;
        height: 2.2rem;
        word-break: break-all;
        font-size: 0.17rem;
      }
    }
    .pro_list_item:hover {
      img {
        transform: scale(1.04);
      }
    }
  }
}
</style>
