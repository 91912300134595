import {createStore} from "vuex";
import router from "../router";

export default createStore({
    state() {
        return {
            userInfo: {},
            account: '',
            show_account_cancel: false
        }
    },
    mutations: {
        setPkUserInfo(state, info) {
            state.userInfo = info;
            localStorage.setItem('pk_userInfo', JSON.stringify(info));
        },
        setAccount(state, account) {
            state.account = account;
            localStorage.setItem('pk_account', JSON.stringify(account));
        },
        logOut() {
            localStorage.removeItem('pk_userInfo')
            location.reload()
        },
        setAccountDeletion(state, payload){
            state.show_account_cancel = payload
        }
    }
})