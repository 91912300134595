<template>
  <div class="mycard_teach">
    <b-header></b-header>
    <img src="../assets/img/appteach.png" alt="" class="teach">
    <down-loads/>
    <b-footer></b-footer>
  </div>
</template>

<script>
import BHeader from "@/components/head";
import BFooter from '@/components/footer'
import DownLoads from "./downLoads.vue";

export default {
  components: {BFooter, BHeader, DownLoads}
};
</script>
<style lang="scss" scoped>
.mycard_teach {
  width: 100%;
  position: relative;

  .teach {
    width: 100%;
  }

  .mycard {
    margin-left: 2.15rem;
  }
}
</style>
