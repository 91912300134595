<script>
import { ie_judge } from "../../utils/utils";
import {computed} from "vue";
import store from "../../store";
export default {
  data(){
    return {

    }
  },
  computed: {
    app_id(){
      return this.$attrs.appid
    }
  },
  mounted() {
  },
  methods:{
    facebookLogin(){
      let _this = this
      if (ie_judge() == 11 || ie_judge() == "edge") {
        console.log(
          "Due to browser compatibility, it is recommended to use chrome or another browser to log in to your Facebook account~"
        );
        return;
      }
      FB.init({
        appId: this.$attrs.appid, // 应用编号
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v16.0'
      })
      FB.getLoginStatus(function (response) {
        if (response && response.status === "connected") {
          FB.logout(function (response) {
            FB.login(function (response) {
              if (response.authResponse) {
                _this.statusChangeCallback(response);
              } else {
                console.log("User cancelled login or did not fully authorize.");
              }
            });
          });
        } else {
          FB.login(function (response) {
            if (response.authResponse) {
              _this.statusChangeCallback(response);
            } else {
              console.log("User cancelled login or did not fully authorize.");
            }
          });
        }
      });
    },
    statusChangeCallback(response) {
      if (response.status == "connected") {
        let params = {
          account: response.authResponse.userID,
          type: "FaceBook",
          password: "",
        };
        this.$emit("fbLogin", params);
      } else {
        console.log("Login failed, please try again later");
      }
    },
  },
};
</script>

<template>
  <div class="fbLogin" @click="facebookLogin">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.fbLogin {
  img {
    width: 1.33rem;
  }
}
</style>
