<template>
  <div class="newdetail_main">
    <m-showdown></m-showdown>
    <go-back class="absolute"/>
    <img src="../assets/img/mb_store/chargebg.png" alt class="newdetail_bg"/>
    <div class="detail_title">{{ msg.message_title }}</div>
    <div class="detail_time">{{ msg.uptime }}</div>
    <div class="detail_" v-html="msg.message_details"></div>
<!--    <img src="../assets/img/goback.png" alt="" class="goback" @click="goback">-->
    <m-footer></m-footer>
  </div>
</template>

<script>
import BHeader from "@/components/head";
import MFooter from "@/components/mb_footer";
import MShowdown from '@/components/showdown'
import GoBack from "./goBack.vue";

export default {
  components: {MFooter, MShowdown, GoBack},
  data() {
    return {
      msg: {}
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.postHttp(
        this,
        {id: this.$route.query.articleId},
        "/mesdes",
        function (obj, data) {
          obj.msg = data;
        }
    );
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.absolute {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  z-index: 10;
  width: 2rem;
}
.newdetail_main {
  width: 100%;
  background-color: #280c37;

  img {
    max-width: 100%;
  }

  .newdetail_bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .detail_title {
    position: absolute;
    font-size: 0.42rem;
    color: white;
    text-align: center;
    top: 5.3rem;
    width: 8rem;
    left: 50%;
    margin-left: -4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .Navigation {
    position: absolute;
    font-size: 0.16rem;
    width: 3rem;
    color: white;
    top: 7.8rem;
    left: 50%;
    margin-left: -5rem;
    text-align: left;
    cursor: default;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .detail_time {
    position: absolute;
    font-size: 0.3rem;
    width: 2rem;
    color: white;
    top: 6.8rem;
    left: 50%;
    margin-left: 2.5rem;
    text-align: right;
  }

  .detail_ {
    font-size: 0.35rem;
    color: white;
    width: 9rem;
    text-align: left;
    margin-top: 7.3rem;
    border-top: 1px solid white;
    padding: 0.4rem 0 2rem;
    position: relative;
    min-height: 14rem;
    left: 50%;
    margin-left: -4.5rem;
    background-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;

    img {
      max-width: 100%
    }
  }

  .goback {
    width: 1.2rem;
    height: 1rem;
    margin: .2rem auto .5rem;
    display: block;
  }

  .footer {
    position: relative;
  }
}
</style>
