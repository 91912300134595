<template>
  <div class="tips">
    <div :class="[show == 1 ? 'tips_body' : 'tips_body rights']">
      <img src="../assets/img/hover.png" alt class="tips_img" />
      <div class="code_box">
        <img :src="fuli" alt />
      </div>
      <p class="code_text">掃我領好康</p>
      <a href="https://line.me/ti/p/s-0lV5MqZp" target="_blank">
        <img src="../assets/img/addfriend.png" alt class="line" />
      </a>
      <a
        href="https://www.facebook.com/BigCard.Club"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../assets/img/fbgroup.png" alt class="fb" />
      </a>
      <a href="https://lin.ee/bnWKU0h" target="_blank">
        <img src="../assets/img/vipserve.png" alt class="vip" />
      </a>
      <router-link to="/appteach" target="_blank"
        ><img src="../assets/img/appteachbtn.png" alt class="appbtn"
      /></router-link>
      <router-link to="/mycard" target="_blank"
        ><img src="../assets/img/mycardbtn.png" alt class="mycardteachbtn"
      /></router-link>
      <router-link to="/mybean" target="_blank"
        ><img src="../assets/img/Mbean.png" alt class="Mbeanbtn"
      /></router-link>
      <img src="../assets/img/showin.png" alt="" class="show" v-on:click="showin(0)" />
    </div>
    <img
      src="../assets/img/showout.png"
      alt=""
      :class="[show == 1 ? 'showout' : 'showout lefts']"
      v-on:click="showin(1)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: 1,
      fuli: "",
    };
  },
  methods: {
    showin(e) {
      var that = this;
      this.show = e;
    },
    // vip() {
    //   this.$emit("vip", true);
    // },
    // line() {
    //   this.$emit("line", true);
    // },
  },
  mounted() {
    //二維碼
    this.getHttp(this, "", "/code", function (obj, data) {
      obj.fuli = data.fuli;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tips {
  position: fixed;
  width: 2.8rem;
  left: 0;
  top: 0.6rem;
  z-index: 0;
  .lefts {
    left: 0 !important;
  }
  .rights {
    left: -2.8rem !important;
  }
  .showout {
    width: 0.5rem;
    height: 0.67rem;
    top: 3.9rem;
    left: -0.5rem;
    position: absolute;
    transition: left 0.5s ease-in;
  }
  .show {
    width: 0.5rem;
    height: 0.67rem;
    top: 3.9rem;
    right: 0.15rem;
    position: absolute;
  }
  .tips_body {
    width: 2.8rem;
    position: absolute;
    left: 0;
    transition: left 0.5s ease-in;
    .tips_img {
      width: 100%;
    }
    .code_box {
      position: absolute;
      width: 1.52rem;
      height: 1.52rem;
      top: 1.88rem;
      left: 0.38rem;
      background: url(../assets/img/codebg.png) no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 1.35rem;
      img {
        width: 1.4rem;
      }
    }
    .code_text {
      color: white;
      font-size: 0.24rem;
      text-align: center;
      position: absolute;
      top: 3.4rem;
      width: 2.3rem;
    }

    .line,
    .fb,
    .vip,
    .appbtn,
    .mycardteachbtn,
    .Mbeanbtn {
      position: absolute;
      width: 1.85rem;
      height: 0.53rem;
      left: 0.23rem;
      cursor: pointer;
    }
    .line {
      top: 3.9rem;
    }
    .fb {
      top: 4.47rem;
    }
    .vip {
      top: 5.03rem;
    }
    .appbtn {
      top: 5.6rem;
    }
    .mycardteachbtn {
      top: 6.16rem;
    }
    .Mbeanbtn {
      top: 6.72rem;
    }
  }
}
</style>
