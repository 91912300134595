<template>
  <div>
    <img class="return" src="../assets/img/backBtn.png" alt  @click="goBack" />
  </div>
</template>
<script>
export default {
  data(){
    return {}
  },
  methods: {
    goBack(){
      this.$router.push('mbindex')
    }
  }
}
</script>
<style>
.returnDiv {
  width: 2rem;
  height: 1rem;
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
  z-index: 1000;
}
.return {
  display: block;
  background: transparent;
  position: absolute;
  float: left;
  margin: 0.5rem;
  width: 1.5rem;
  z-index: 1000;
}
</style>