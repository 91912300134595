<template>
  <div class="footer"></div>
</template>

<script>

export default {
  data() {
    return {};
  },
  mounted: function() {},
  methods: {}
};
</script>
<style lang="scss" scope>
.footer {
  height: 2.17rem;
  width: 100%;
  background: url(../assets/img/footer.png) no-repeat;
  background-size: 100% 100%;
  .footer_in {
    position: relative;
    width: 3rem;
    margin: 0 auto;
    overflow: hidden;
    height: .3rem;
    /* padding-top: 57px; */
    top: .55rem;
    left: 50%;
    margin-left: -3.05rem;
  }
  a {
    position: absolute;
    top: 0;
    /* text-indent: -10000px; */
    opacity: 0;
  }
  .intr {
    left: 0;
  }
  .concat {
    left: 69px;
  }
  .service {
    left: 140px;
  }
  .private {
    left: 202px;
  }
}
</style>
