<template>
  <div class="newdetail_main">
    <b-header></b-header>
    <img src="../assets/img/pc_new/charge_center_bg.png" alt class="newdetail_bg" />
    <img src="../assets/img/pc_new/charge_center_logo.png" alt class="bg-logo" />
    <div class="detail_title">{{ msg.message_title }}</div>
    <div class="Navigation">
      當前所在位置： <router-link to="/newlist">活動公告</router-link> >
      {{ msg.message_type }}
    </div>
    <div class="detail_time">{{ msg.uptime }}</div>
    <div class="detail_" v-html="msg.message_details"></div>
    <b-footer></b-footer>
  </div>
</template>
<script>
import BHeader from "@/components/head";
import BFooter from "@/components/footer";
export default {
  components: { BHeader, BFooter },
  data() {
    return {
      msg: {},
    };
  },
  methods: {},
  mounted() {
    this.postHttp(
      this,
      { id: this.$route.query.articleId },
      "/mesdes",
      function (obj, data) {
        obj.msg = data;
      }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.newdetail_main {
  width: 100%;
  background-color: #141647;
  img {
    max-width: 100%;
  }
  .newdetail_bg {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bg-logo {
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4.3rem;
  }
  .detail_title {
    position: absolute;
    font-size: 0.42rem;
    color: white;
    text-align: center;
    top: 6.5rem;
    width: 10rem;

    left: 50%;
    margin-left: -5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .Navigation {
    position: absolute;
    font-size: 0.16rem;
    width: 3rem;
    color: white;
    top: 7.8rem;
    left: 50%;
    margin-left: -5rem;
    text-align: left;
    cursor: default;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .detail_time {
    position: absolute;
    font-size: 0.16rem;
    width: 2rem;
    color: white;
    top: 7.8rem;
    left: 50%;
    margin-left: 3rem;
    text-align: right;
  }
  .detail_ {
    font-size: 0.24rem;
    color: white;
    width: 10rem;
    text-align: left;
    margin-top: 8.1rem;
    border-top: 1px solid white;
    padding: 0.4rem 0 2rem;
    position: relative;
    min-height: 9.8rem;
    left: 50%;
    margin-left: -5rem;
    background-color: rgba(0, 0, 0, 0);
    img {
      max-width: 100%;
    }
  }
  .footer {
    position: relative;
  }
}
</style>
