<template>
  <div class="mb-custom-rule">
    <go-back/>
    <img alt class="logo"/>
    <img src="../assets/img/customer/mbRuleContent.png" alt class="mb-custom-rule-bg"/>
    <m-footer/>
  </div>
</template>
<script>
import goBack from "./goBack.vue";
import MFooter from "./mb_footer.vue";
export default {
  components: { goBack, MFooter }
}
</script>
<style lang="scss" scoped>
$background-size: 100% 100%;
img {
  display: block;
  margin: auto;
}
.mb-custom-rule-bg {
  width: 10rem;
  object-fit: cover;
}
.logo {
  //background: url("../assets/img");
}
</style>