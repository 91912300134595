<template>
  <div class="mb_newlist_main" :style="{height:scrollerHeight}">
    <GoBack/>
    <m-showdown></m-showdown>
    <img src="../assets/img/mb_newlistbg.png" alt class="newlist_bg" />
    <div class="changeNew">
      <div :class='[targets==1?"newTarget active":"newTarget"]' @click="targetList(1)">遊戲公告</div>
      <div :class='[targets==2?"activeTarget active":"activeTarget"]' @click="targetList(2)">活動消息</div>
    </div>
    <div class="list_dom">
       <div class="newlist_"  v-for="(item,index) in massageList" :key="index">
        <div class="new_item" @click="gotoDeatil(item.id)">
            <div class="new_item_type">
              <span v-if="targets==1">公告</span>
              <span v-else>活動</span>
            </div>
            <div class="new_item_title">
              <p class="title_">
                {{item.message_title}}
              </p>
              <p class="describe">
                 {{item.message_des}}
              </p>
              </div>
            <div class="new_item_time">{{item.uptime}}</div>
        </div>
    </div>
    </div>
    <mo-paging :total="totalnum" @input="changeList" :prepage="5" :show-page="allnum"  v-model="sPage"></mo-paging>
<!--    <div class="goback" @click="goback"></div>-->
    <m-footer></m-footer>
  </div>
</template>

<script>
import MFooter from "@/components/mb_footer";
import MShowdown from '@/components/showdown';
import GoBack from "./goBack.vue";
import MoPaging from './paging'
export default {
  components: { MFooter ,MoPaging,MShowdown,GoBack},
  data() {
    return {
       sPage:1,
       massageList : [],
       neworactive:true,
       allnum:0,
       totalnum:0,
       targets:1,
       type:1
    };
  },
  mounted: function() {
      this.getList(1)
  },
  computed: {
    scrollerHeight: function() {
      return document.documentElement.clientHeight + "px";
    }
  },
  methods: {
    goback(){
this.$router.go(-1);
    },
    gotoDeatil(e){
      this.$router.push({ path: '/mbnewdetail', query: { articleId: e }});
    },
    getList (e) {
        this.massageList = []
        let datas = {
            num: 5,
            page: e,
            type: this.type
        };
        this.getHttp(this, datas, "/message", function(obj, data) {
          
          obj.allnum = data.pagenum;
          obj.totalnum = data.count;
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      }); 
     },
    changeList(e){
        this.getList(e)
    },
    //从page组件传递过来的当前page
    pageChange (page) {
        this.currentPage = page
        this.getList()
    },
    targetList(e){
      this.targets = e;
      this.type = e;
      this.sPage = 1;
      this.massageList = []
        let datas = {
            num: 5,
            page: 1,
            type: e
        };
        this.getHttp(this, datas, "/message", function(obj, data) {
          console.log(data.pagenum)
          obj.allnum = data.pagenum;
          obj.totalnum = data.count;
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      }); 
    }
  }
};
</script>
<style lang="scss" scope>
.mb_newlist_main {
  width: 100%;
  position: relative;
  background-color: #350d4c;
  .newlist_bg{
      width: 100%
  }
  .mb_footer{
    //  position: absolute;
    //  bottom: 0;
  }
  .goback{
        width: 1rem;
    height: 1rem;
    position: absolute;
    top: 15.6rem;
    left: 50%;
    margin-left: -.5rem;
  }
  .changeNew{
    position: absolute;
    top: 6rem;
    font-size: .5rem;
    width: 6rem;
    left: 50%;
    color: white;
    margin-left: -3rem;
    line-height: .6rem;
    .newTarget,.activeTarget{
      width:50%;
      float: left;
      cursor: pointer;
    }
    .active{
      color: #fff260;
      border-bottom: 3px solid #fff260;
    }
  }
  .list_dom{
    position: absolute;
    width: 100%;
    left: 0;
    top: 6.7rem;
  }
  .newlist_{
      float: left;
      width: 100%;
      font-size: .25rem;
      color: white;
      text-align: center;
      background-color: #221a49;
      border-bottom: 1px solid white;
      .new_item{
          line-height: 1.7rem;
          width:100%;
          transition: all .4s ease-in;
          cursor: default;
          padding:0 2% 0 3%;
          .new_item_type{
            float: left;
            width: 15%;
            line-height: 1.4rem;
            span{
              display: inline-block;
              padding:5px 5px;
              border-radius: 3px;
              font-size: .2rem;
              line-height: .2rem;
              background-color: #ffc116
            }
          }
          .new_item_title{
              width: 60%;
              text-align: left;
              float: left;
              padding-top: .34rem;
              
              .title_{
                line-height: .7rem;
                overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: .4rem;
              }
              .describe{
                line-height: .4rem;
                font-size: .24rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
          }
          .new_item_time{
              width: 22%;
              text-align: right;
              float: left;
              font-size: .3rem;
          }
      }
  }
  .pagination{
      margin-left: -3rem;
      font-size: .3rem;
  }
}
</style>