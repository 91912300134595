<template>
  <div class="mybean_teach">
    <b-header></b-header>
    <img src="../assets/img/mybeanteach.png" alt="" class="teach">
    <down-loads/>
    <b-footer></b-footer>
  </div>
</template>

<script>
import BHeader from "@/components/head";
import BFooter from '@/components/footer'
import DownLoads from "./downLoads.vue";

export default {
  components: {BFooter, BHeader, DownLoads}
};
</script>
<style lang="scss" scope>
.mybean_teach {
  width: 100%;
  position: relative;

  .teach {
    width: 100%;
  }

  .appdown,
  .googledown,
  .mycard {
    position: absolute;
    width: 2.4rem;
    top: 4.2rem;
    left: 50%;
    cursor: pointer;
    transition: all .4s ease-in;
  }

  .appdown {
    margin-left: -4.5rem;
  }

  .googledown {
    margin-left: -1.2rem;
  }

  .mycard {
    margin-left: 2.15rem;
  }
}
</style>
