<template>
  <div class="mb_mycard_teach">
    <go-back></go-back>
     <m-showdown></m-showdown>
      <img src="../assets/img/mycardteach_mb.png" alt="" class="teach">
<!--      <a :href="appLine"><img src="../assets/img/app.png" alt class="appdown" /></a>-->
<!--      <a :href="googleLink"><img src="../assets/img/google.png" alt class="googledown" /></a>-->
<!--      <a :href="mycardlink"><img src="../assets/img/mycard.png" alt class="mycard" /></a>-->
<!--      <div class="goback" @click="goback"></div>-->
      <m-footer></m-footer>
  </div>
</template>

<script>

import MFooter from '@/components/mb_footer';
import MShowdown from '@/components/showdown';
import GoBack from "./goBack.vue";
export default {
     components: {MFooter,MShowdown, GoBack},
  data() {
    return {
        googleLink:'https://play.google.com/store/apps/details?id=com.qp.dpk',
        appLine:'',
        mycardlink:''
    };
  },
  mounted: function() {
      //下載鏈接
    this.getHttp(this, "", "/link", function(obj, data) {
      obj.googleLink = data.google;
      obj.appLine = data.apple;
      obj.mycardlink = data.mycard;
    });
    
  },
  methods: {
      goback(){
      this.$router.go(-1)
    }
  }
};
</script>
<style lang="scss" scoped>
.mb_mycard_teach{
    width: 100%;
    position: relative;
    .teach{
        width: 100%;
    }
    .goback{
    position: absolute;
    width: 1rem;
    height:1rem;
    bottom: 1.5rem;
    left: 50%;
    margin-left: -.5rem;
  }
  .appdown,
  .googledown,
  .mycard {
    position: absolute;
    width: 2.4rem;
    top: 3.3rem;
    left: 50%;
    cursor: pointer;
    transition: all .4s ease-in;
  }
  .appdown:hover,
  .googledown:hover,
  .mycard:hover {
    transform: scale(1.05)
  }
  .appdown {
    margin-left: -4rem;
  }
  .googledown {
    margin-left: -1.2rem;
  }
  .mycard {
    margin-left: 1.65rem;
  }
}
</style>