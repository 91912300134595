<template>
  <div class="newlist_main">
    <b-header></b-header>
    <img src="../assets/img/pc_new/charge_center_bg.png" alt class="newlist_bg" />
    <img src="../assets/img/pc_new/ads.png" alt="" class="title" />
    <img src="../assets/img/logo.png" alt="" class="ads-logo" />
    <div class="changeNew">
      <div
        :class="[targets == 1 ? 'newTarget active' : 'newTarget']"
        @click="targetList(1)"
      >
        遊戲公告
      </div>
      <div
        :class="[targets == 2 ? 'activeTarget active' : 'activeTarget']"
        @click="targetList(2)"
      >
        活動消息
      </div>
    </div>
    <div class="list_dom">
      <div class="newlist_" v-for="(item, index) in massageList" :key="index">
        <div class="new_item" @click="gotoDeatil(item.id)">
          <div class="new_item_type">
            <span v-if="targets == 1">公告</span>
            <span v-else>活動</span>
          </div>
          <div class="new_item_title">
            <p class="title_">
              {{ item.message_title }}
            </p>
            <p class="describe">
              {{ item.message_des }}
            </p>
          </div>
          <div class="new_item_time">{{ item.uptime }}</div>
        </div>
      </div>
    </div>
    <mo-paging
      :total="totalnum"
      @input="changeList"
      :prepage="4"
      :show-page="allnum"
      v-model="sPage"
    ></mo-paging>
    <b-footer></b-footer>
  </div>
</template>

<script>
import BHeader from "@/components/head";
import BFooter from "@/components/footer";
import MoPaging from "./paging";
export default {
  components: { BHeader, BFooter, MoPaging },
  data() {
    return {
      sPage: 1,
      massageList: [],
      neworactive: true,
      allnum: 0,
      totalnum: 0,
      targets: 1,
      type: 1,
    };
  },
  mounted: function () {
    this.getList(1);
  },
  methods: {
    gotoDeatil(e) {
      this.$router.push({ path: "/newdetail", query: { articleId: e } });
    },
    getList(e) {
      this.massageList = [];
      let datas = {
        num: 4,
        page: e,
        type: this.type,
      };
      this.getHttp(this, datas, "/message", function (obj, data) {
        obj.allnum = data.pagenum;
        obj.totalnum = data.count;
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      });
    },
    changeList(e) {
      this.getList(e);
    },
    //从page组件传递过来的当前page
    pageChange(page) {
      this.currentPage = page;
      this.getList();
    },
    targetList(e) {
      this.targets = e;
      this.type = e;
      this.sPage = 1;
      this.massageList = [];
      let datas = {
        num: 4,
        page: 1,
        type: e,
      };
      this.getHttp(this, datas, "/message", function (obj, data) {
        obj.allnum = data.pagenum;
        obj.totalnum = data.count;
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
.newlist_main {
  width: 100%;
  position: relative;
  .newlist_bg {
    width: 100%;
  }
  .title {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 5.8rem;
  }
  .ads-logo {
    position: absolute;
    top: 1.2rem;
    left: 23%;
    width: 2.1rem;
  }
  .changeNew {
    position: absolute;
    top: 6.55rem;
    font-size: 0.3rem;
    width: 4.4rem;
    left: 50%;
    color: white;
    margin-left: -2.5rem;
    line-height: 0.6rem;
    .newTarget,
    .activeTarget {
      width: 50%;
      float: left;
      cursor: pointer;
    }
    .active {
      color: #fff260;
      border-bottom: 3px solid #fff260;
    }
  }
  .list_dom {
    position: absolute;
    width: 100%;
    left: 0;
    top: 7.19rem;
  }
  .newlist_ {
    float: left;
    width: 100%;
    font-size: 0.25rem;
    color: white;
    text-align: center;
    background-color: #221a49;
    border-bottom: 1px solid white;
    .new_item {
      line-height: 1.7rem;
      width: 12rem;
      margin-left: 3.6rem;
      transition: all 0.4s ease-in;
      cursor: default;
      .new_item_type {
        float: left;
        width: 10%;
        line-height: 1.4rem;
        span {
          display: inline-block;
          padding: 5px 8px;
          border-radius: 3px;
          font-size: 0.2rem;
          line-height: 0.2rem;
          background-color: #ffc116;
        }
      }
      .new_item_title {
        width: 60%;
        text-align: left;
        float: left;
        padding-top: 0.34rem;

        .title_ {
          line-height: 0.7rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .describe {
          line-height: 0.4rem;
          font-size: 0.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .new_item_time {
        width: 30%;
        text-align: center;
        float: left;
      }
    }
  }
  .newlist_:hover {
    background-color: #6b618c;
    .new_item {
      margin-left: 3.3rem;
    }
  }
}
</style>
