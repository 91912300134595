<template>
  <div>
    <a :href="applink">
      <img src="../assets/img/app.png" alt class="appdown"/>
    </a>
    <img @click="showTip" src="../assets/img/google.png" alt class="googledown"/>
    <a :href="mycardlink">
      <img src="../assets/img/mycard.png" alt class="mycard"/>
    </a>
    <div v-if="tipDialog" class="popup">
      <p>尚未開放 敬請期待</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        applink: '',
        googlelink: '',
        mycardlink: '',
        tipDialog: false
      }
    },
    created() {
      //下載鏈接
      this.getHttp(this, "", "/link", function (obj, data) {
        obj.googleLink = data.google;
        obj.applink = data.apple;
        obj.mycardlink = data.mycard;
      });
    },
    methods: {
      showTip(){
        this.tipDialog = true
        setTimeout(() => {
          this.tipDialog = false
        }, 2000)
      },
    }
  }
</script>
<style scoped>
.appdown,
.googledown,
.mycard {
  position: absolute;
  width: 2.4rem;
  top: 7.8rem;
  left: 50%;
  cursor: pointer;
  transition: all 0.4s ease-in;
}

.appdown:hover,
.googledown:hover,
.mycard:hover {
  transform: scale(1.05);
}

.appdown {
  margin-left: -5rem;
}

.googledown {
  margin-left: -1.2rem;
}

.mycard {
  margin-left: 2.65rem;
}

.popup {
  width: 200px;
  position: fixed;
  top: 50%;
  left: calc(50% - 100px);
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.76);
  padding: 10px 20px;
  border-radius: 8px;
  animation: fadeIn 0.3s;
}
</style>
