<template>
      <div class="pagination">
        <span class="total">共 {{total}} 條 / {{pages}} 頁</span>

        <span class="prev" :class="{'disabled':value==1}" @click="prePageHandle">&lt;</span>

        <span class="page" v-for="page in showPage"
        :key="page"
        :class="{'active':(changeStart + page-1)==value}"
        @click="changeShow(changeStart + page-1)"
        >{{changeStart + page-1}}</span>

        <span class="next" :class="{'disabled':value==pages}" @click="nextPageHandle">&gt;</span>

    </div>

</template>
<script>
export default {
    name : 'MoPaging',
    //通过props来接受从父组件传递过来的值
    props : {
        total:{
                type:Number
            },
            prepage:{
                type:Number
            },
            showPage:{//页面中要显示几个页码
                type:Number,
                default:3
            },
            value:{
                type:Number
            }
    },
    methods : {
        changeShow(page){
                this.currentPage = page
                this.$emit('input',page)

            },
            jump(){
                if(this.val<1){
                    this.val = 1
                }
                if(this.val>this.pages){
                    this.val = this.pages
                }
                this.val = Math.floor(this.val)
                this.$emit('input',this.val)
                this.currentPage = this.val
                this.val = ''
            },
            prePageHandle(){
                this.currentPage--
                if(this.currentPage<1){
                    this.currentPage = 1
                }
                this.$emit('input',this.currentPage)
            },
            nextPageHandle(){
                this.currentPage++
                if(this.currentPage>this.pages){
                    this.currentPage = this.pages
                }
                this.$emit('input',this.currentPage)

            }
    },
    computed : {
        changeStart(){//分页逻辑
                if(this.pages<=this.showPage){
                   return 1
                }else{
                    if(this.currentPage<=this.posPage){
                        return 1
                    }else if(this.pages-this.currentPage<=this.posPage){
                        return this.pages - this.showPage + 1
                    }else{
                        return  this.currentPage - this.posPage + 1
                    }
                }
        },
        pages(){
            return Math.ceil(this.total/this.prepage)
        }
    },
    data () {
        return {
                //计算需要展示的总页数
                val:'',//记录输入框中的值
                currentPage:1,//记录当前页
                posPage:Math.floor(this.showPage/2)+1,//超过这个页码时，固定显示的位置
                start:1
        }
    },
    mounted(){
        console.log(this.showPage)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box {
            margin: 100px auto 0;
            width: 80%;
        }
        .clear:after {
            content: '';
            display: block;
            clear: both;
        }

        .pagination {
            margin: 10px 0;
            font-size: .23rem;
            position: absolute;
            top: 15.5rem;
            left: 50%;
            margin-left: -2rem;
            color: white;
        }

        .pagination span {
            margin: 0 10px;
        }

        .pagination span.page, .pagination span.prev, .pagination span.next {
            cursor: pointer;
        }

        .pagination span.page:hover {
            color: #409eff;
        }

        .pagination span.page.active {
            color: #409eff;
            cursor: default;
        }

        .pagination span.prev.disabled, .pagination span.next.disabled {
            color: #cccccc;
            cursor: not-allowed;
        }

        .pagination input[type='text'] {
            padding: 2px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            width: 60px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            outline: none;
            transition: .2s all;
        }
        .pagination input[type='text']:focus {
            border-color: #409eff;
        }
</style>
