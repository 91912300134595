<template>
  <div class="mb_footer">
    <div class="trems">
      <p>※遊戲內容依遊戲軟體分級管理辦法第六條，應改列輔導十五歲級</p>
      <p>※本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務</p>
      <p>※本遊戲內容涉及棋奕、牌類及益智及娛樂類</p>
      <p>※本遊戲以成年人為訴求對象，請注意遊戲時間，避免沉迷</p>
      <p>※本遊戲設計為休閒娛樂而非現金博奕，僅提供使用者娛樂用途</p>
      <p>※本遊戲不提供現金交易賭博，無法贏得現金或實體獎品</p>
      <p>※本遊戲由沃坦廣告行銷有限公司代理，如有疑問，請以本遊戲客服管道聯繫</p>
    </div>
    <img class="footer_age" src="../assets/img/footer_age.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted: function () {},
  methods: {},
};
</script>
<style lang="scss" scope>
.mb_footer {
  display: flex;
  color: #fff;
  background: #000;
  padding: 0.2667rem;
  .trems {
    text-align: left;
    font-weight: lighter;
    font-size: 0.2667rem;
  }
  .footer_age {
    margin-left: 0.2667rem;
    width: 0.7333rem;
  }
}
</style>
