<!--
 * @Descripttion: 
 * @version: 
 * @Author: liy
 * @Date: 2023-04-03 10:06:23
 * @LastEditTime: 2023-04-14 15:43:45
-->
<script>
import { ie_judge } from "../../utils/utils";
export default {
  data() {
    return {};
  },
  methods: {
    async facebookLogin(device) {
      // appid android:315647499309742 ios:510185113529844;
      let appid = "";
      if (device == "android") {
        appid = 315647499309742;
      } else if (device == "ios") {
        appid = 510185113529844;
      }
      await FB.init({
        appId: appid, // 应用编号
        autoLogAppEvents: true,
        xfbml: true,
        version: "v16.0",
      });
      let _this = this;
      if (ie_judge() == 11 || ie_judge() == "edge") {
        console.log(
          "Due to browser compatibility, it is recommended to use chrome or another browser to log in to your Facebook account~"
        );
        return;
      }
      FB.getLoginStatus(function (response) {
        if (response && response.status === "connected") {
          FB.logout(function (response) {
            FB.login(function (response) {
              if (response.authResponse) {
                _this.statusChangeCallback(response);
              } else {
                console.log("User cancelled login or did not fully authorize.");
              }
            });
          });
        } else {
          FB.login(function (response) {
            if (response.authResponse) {
              _this.statusChangeCallback(response);
            } else {
              console.log("User cancelled login or did not fully authorize.");
            }
          });
        }
      });
    },
    statusChangeCallback(response) {
      console.log(response);
      if (response.status == "connected") {
        let params = {
          account: response.authResponse.userID,
          type: "FaceBook",
          password: "",
        };
        this.$emit("facebookLogin", params);
      } else {
        console.log("Login failed, please try again later");
      }
    },
  },
};
</script>

<template>
  <div class="fbLogin flexColumn">
    <img
      src="../../assets/img/pc_new/fb-gp.png"
      @click="facebookLogin('android')"
      alt=""
      class="hoverScale"
    />
    <img
      src="../../assets/img/pc_new/fb-ios.png"
      class="hoverScale"
      @click="facebookLogin('ios')"
      alt=""
    />
  </div>
</template>

<style lang="scss" scoped>
.fbLogin {
  img {
    width: 5.0667rem;
    cursor: pointer;
    display: block;
  }
  img:nth-child(1) {
    margin-bottom: 15px;
  }
  span {
    font-size: 12px;
    color: #fff;
  }
}
</style>
