<template>
  <div class="mbtips_main" :style="{ height: srollheight }">
    <img src="../assets/img/close.png" alt class="close" @click="closembtips" />
    <ul class="meauList">
      <router-link to="/mbproduce">
        <li>产品介绍</li>
      </router-link>
      <li @click="showlayer">客服中心</li>
      <li @click="goCharge">儲值中心</li>
      <li @click="getLine">
        <img src="../assets/img/lineicon.png" alt class="icon_" />加入官方LINE
      </li>
      <a href="https://www.facebook.com/BigCard.Club">
        <li><img src="../assets/img/fbicon.png" alt class="icon_" /> FB粉絲團</li>
      </a>
      <li @click="getMsg()">
        <img src="../assets/img/lineicon.png" alt class="icon_" /> VIP專屬客服
      </li>
      <router-link to="/mbappteach">
        <li><img src="../assets/img/appicon.png" alt class="icon_" /> iOS安裝教學</li>
      </router-link>
      <router-link to="/mbmycard">
        <li><img src="../assets/img/mycardicon.png" alt class="icon_" />點卡儲值教學</li>
      </router-link>
      <router-link to="/mbmybean">
        <li><img src="../assets/img/beanicon.png" alt class="icon_" /> Mbean儲值教學</li>
      </router-link>
    </ul>
    <transition name="scale">
      <b-Wechat @funcW="getMsg" v-show="showWechat"></b-Wechat>
    </transition>
    <transition name="scale">
      <b-Line @func="getLine" v-show="showline"></b-Line>
    </transition>
  </div>
</template>

<script>
import BWechat from "@/components/alert1";
import BLine from "@/components/alert2";

export default {
  components: { BWechat, BLine },
  data() {
    return {
      showWechat: false,
      showline: false,
    };
  },
  created() {},
  mounted: function () {
    this.scrollLock();
  },
  methods: {
    scrollLock() {
      document.body.addEventListener("touchmove", this.bodyScroll, {
        passive: false,
      });
      document.body.addEventListener("wheel", this.bodyScroll, {
        passive: false,
      });
    },
    getLine() {
      window.open("https://line.me/ti/p/s-0lV5MqZp");
      // this.showline = e;
    },
    getMsg() {
      window.open("https://lin.ee/bnWKU0h");
      // this.showWechat = e;
    },
    bodyScroll(event) {
      event.preventDefault();
    },
    closembtips() {
      this.$emit("mbtipsout", false);
    },
    showlayer() {
      this.$router.push({
        path: "/mbCustomer",
      });
      // this.$layer.msg('很抱歉，官網客服中心正在維護中。還請登入大牌咖官方粉絲頁：www.fb.com/bigcard.club，聯系主頁編輯反映您的問題！',{time:3})
    },
    goCharge() {
      this.$router.push({
        path: "/mbCharge",
      });
    },
  },
  computed: {
    srollheight() {
      return window.screen.height + "px";
    },
  },
  destroyed() {
    document.body.removeEventListener("touchmove", this.bodyScroll, {
      passive: false,
    });
  },
};
</script>
<style lang="scss" scope>
/* 平板 */

.mbtips_main {
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  position: absolute;

  .close {
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    top: 0.3rem;
    right: 0.35rem;
  }
  @media only screen and (max-width: 375px) {
    .meauList {
      padding: 1.1rem 0.8rem 0 0.8rem !important;
    }
  }
  .meauList {
    width: 100%;
    list-style: none;
    padding: 2rem 0.8rem 0 0.8rem;

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      font-size: 0.5rem;
      color: #333;
      line-height: 1.5rem;
      font-weight: 600;
      border-bottom: 1px solid #333;
      position: relative;

      .icon_ {
        position: absolute;
        width: 1rem;
        top: 0.2rem;
        left: 1.2rem;
      }

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        line-height: 1.5rem;
        .icon_ {
          top: 0.25rem;
        }
      }
    }
  }
}
</style>
