<template>
  <div class="pc_popup">
    <div class="mask"></div>
    <!-- 登录框 -->
    <transition>
      <div class="login_pop_up pop_up_bg" v-if="displayLogin || showLogin">
        <img src="../assets/img/pc_new/x.png" @click="closePop" alt="" class="x" />
        <form action="" class="flexCloumn">
          <label for="username">登入帳號</label>
          <input
            type="text"
            autocomplete="off"
            minlength="6"
            maxlength="18"
            v-model="loginParams.account"
            placeholder="Enter 6~18 characters or number"
          />
          <label for="password">密碼</label>
          <input
            type="password"
            autocomplete="off"
            minlength="6"
            maxlength="18"
            v-model="loginParams.password"
            placeholder="Enter 6~18 characters or number"
          />
          <img
            src="../assets/img/pc_new/sign_in_btn.png"
            alt=""
            class="sign_in_btn pc_btn"
            @click="signIn"
          />
        </form>

        <div class="thirdLogin bt">
          <div class="third-login-content flexCloumn">
            <div class="gg-btn">
              <img
                class="pointer hoverScale"
                src="../assets/img/pc_new/google_btn.png"
                alt=""
                @click="googleLogin"
              />
            </div>
            <FacebookLogin @facebookLogin="facebookLogin" />
          </div>
        </div>
      </div>
    </transition>
    <!-- 登录成功或失败框 -->
    <transition>
      <div
        class="login_result"
        v-if="signInResult || showChargeSuccess || showChargeFailed"
      >
        <img src="../assets/img/pc_new/x.png" @click="closePop" alt="" class="x" />
        <div class="login_result_bg">
          <img
            v-if="isSignIn || showChargeSuccess"
            src="../assets/img/pc_new/icon_success.png"
            alt=""
            class="icon"
          />
          <img v-else src="../assets/img/pc_new/icon_fail.png" alt="" class="icon" />
          <p>
            {{
              isSignIn
                ? "登入成功"
                : showChargeSuccess
                ? "儲值成功"
                : showChargeFailed
                ? "儲值失敗"
                : failText
            }}
          </p>
        </div>
      </div>
    </transition>
    <!-- 客服中心框 -->
    <transition>
      <div class="customer_center customer" v-if="displayCustomer">
        <img src="../assets/img/pc_new/x.png" @click="closePop" alt="" class="x" />
        <div class="customer_center_item flexColumn">
          <img
            class="pointer hoverScale"
            src="../assets/img/pc_new/go_contact_btn.png"
            alt=""
            @click="goContactUs"
          />

          <img
            class="pointer hoverScale"
            src="../assets/img/pc_new/show_rule_btn.png"
            @click="showRule"
            alt=""
          />
          <img
            class="pointer hoverScale"
            src="../assets/img/pc_new/free_coin_btn.png"
            @click="showCoin"
            alt=""
          />
        </div>
      </div>
    </transition>
    <!-- 游戏管理规章 -->
    <transition>
      <div class="customer" v-if="rulePopUp">
        <img src="../assets/img/pc_new/x.png" @click="closePop" alt="" class="x" />
        <img src="../assets/img/pc_new/rule_pop_up.png" alt="" />
      </div>
    </transition>
    <!-- 免费金币 -->
    <transition>
      <div class="coin_pop_up pop_up_bg" v-if="coinPopuUp">
        <img src="../assets/img/pc_new/x.png" @click="closePop" alt="" class="x" />
        <div class="coin_list flexColumn">
          <a
            class="coin_item flex"
            href="https://line.me/ti/p/s-0lV5MqZp"
            target="_blank"
          >
            <img src="../assets/img/pc_new/line_icon.png" alt="" />
            <span>LINE</span>
          </a>
          <a class="coin_item flex" href="https://wa.me/+886979167925" target="_blank">
            <img src="../assets/img/pc_new/whatsApp_icon.png" alt="" />
            <span>WhatsApp</span>
          </a>
          <a class="coin_item flex" href="https://t.me/TG521uu" target="_blank">
            <img src="../assets/img/pc_new/telegram_icon.png" alt="" />
            <span>Telegram</span>
          </a>
        </div>
        <div class="bt">聯繫客服領取金幣！</div>
      </div>
    </transition>
  </div>
</template>
<script>
import store from "../pcStore.js";
import FacebookLogin from "./thirdLogin/FacebookLoginPC.vue";
import axios from "axios";
import xml2js from "xml2js";
import { getHashSearchParam } from "../utils/common";
export default {
  components: {
    FacebookLogin,
  },
  data() {
    return {
      signInResult: false,
      isSignIn: false,
      rulePopUp: false,
      coinPopuUp: false,
      failText: '',
      loginParams: {
        account: "",
        password: "",
        type: "MBean",
      },
      app_id: "0xlnxs",
      redirect_url: "",
      hrefUrl: "",
    };
  },
  props: {
    loginPopUp: Boolean,
    customerCenter: Boolean,
    showChargeSuccess: Boolean,
    showChargeFailed: Boolean,
  },
  computed: {
    displayLogin: {
      get() {
        return this.loginPopUp;
      },
      set(val) {
        this.$emit("update:loginPopUp", val);
      },
    },
    displayCustomer: {
      get() {
        return this.customerCenter;
      },
      set(val) {
        this.$emit("update:customerCenter", val);
      },
    },
    showLogin: {
      get() {
        return store.state.showLogin;
      },
      set(val) {
        store.commit("showLogin", val);
      },
    },
  },
  created() {
    let url = window.location.href;
    this.getP();
    if (url == "https://home.lukycube.com/#/") {
      this.redirect_url = "https%3A%2F%2Fhome.lukycube.com%2F%23%2F";
    }
    if (url == "https://home.lukycube.com/#/produce") {
      this.redirect_url = "https%3A%2F%2Fhome.lukycube.com%2F%23%2Fproduce";
    }
    if (url == "https://home.lukycube.com/#/chargeCenter") {
      this.redirect_url = "https%3A%2F%2Fhome.lukycube.com%2F%23%2FchargeCenter";
    }
  },
  methods: {
    getP() {
      let account = getHashSearchParam("account");
      let nickName = store.state.nickName;
      if (account && nickName) {
        this.signInResult = true;
        this.isSignIn = true;
      } else if (account && !nickName) {
        this.signInResult = true;
        this.isSignIn = false;
      }
    },
    closePop() {
      let url = window.location.href;
      let parIndex = url.indexOf("?");
      if (parIndex > 0) {
        this.hrefUrl = url.slice(0, parIndex);
      } else {
        this.hrefUrl = url;
      }

      this.displayLogin = false;
      this.displayCustomer = false;
      this.coinPopuUp = false;
      this.rulePopUp = false;
      this.signInResult = false;
      this.showLogin = false;
      if (this.showChargeSuccess || this.showChargeFailed) {
        this.$emit("closeChargePopUp");
      }
      window.location.href = this.hrefUrl;
    },
    signIn() {
      this.login(this.loginParams);
    },
    showRule() {
      this.displayCustomer = true;
      this.rulePopUp = true;
    },
    showCoin() {
      this.displayCustomer = true;
      this.coinPopuUp = true;
    },
    goContactUs() {
      window.open("https://www.facebook.com/BigCard.Club");
    },
    popup() {
      this.showLogin = false;
      this.displayLogin = true;
      this.signInResult = true;
    },
    login(loginParams) {
      if (!loginParams.account || !loginParams.password) {
        this.$layer.msg("請填寫帳號和密碼");
        return;
      }
      this.inApi(loginParams);
    },
    inApi(loginParams) {
      this.postHttp(this, loginParams, "/login", async (obj, data) => {
        if (data.code === 200) {
          store.commit("setUserInfo", data.data);
          await this.getNickName(data.data.uuid);
        } else {
          this.popup();
          this.isSignIn = false;
          this.failText = data.message
        }
      });
    },
    facebookLogin(params) {
      this.inApi(params);
    },
    googleLogin() {
      window.location.href =
        "https://sdk.gamemorefun.com/rest/v2/passport/google/5?app_id=" +
        this.app_id +
        "&redirect_url=" +
        this.redirect_url;
    },
    async getNickName(uuid) {
      if (uuid) {
        await axios
          .get(`https://dpk.lukycube.com:8198/MF_USERINFO.asmx/USERINFO?UUID=${uuid}`)
          .then(async (res) => {
            if (res.status === 200) {
              let xml = await res.data;
              xml2js.parseString(xml, async (err, result) => {
                if (err) {
                  console.log(err);
                } else {
                  let userInfo = await JSON.parse(result.string._).Rows[0];
                  store.commit("setNickName", userInfo[0]);
                  this.popup();
                  this.isSignIn = true;
                }
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$bgColor: #292cad;

a {
  color: #fff;
  outline: none;
  text-decoration: none;
}

.pc_popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 3;
    overflow-x: hidden;
  }

  .pop_up_bg {
    position: fixed;
    top: 16%;
    left: calc(50% - 3rem);
    width: 6rem;
    height: 5.2rem;
    border: 6px solid #1c8cf6;
    border-radius: 30px;
    box-shadow: inset 0 0 10px 4px #3154e6;
    background: $bgColor;
    z-index: 9;
  }
  .login_pop_up {
    height: 6.5rem;
    .bt {
      height: 2.2rem;
    }
  }
  form {
    width: 61%;
    align-items: flex-start;
    margin: 15% auto 0;

    label {
      padding: 6px 0;
      font-size: 0.28rem;
      color: #fae02d;
    }

    input {
      width: 100%;
      height: 0.5rem;
      border-radius: 0.14rem;
    }

    .sign_in_btn {
      margin-top: 0.27rem;
      cursor: pointer;
    }
  }

  .bt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    height: 1.2rem;
    background: #151779;
  }

  .thirdLogin {
    img {
      width: 0.6rem;
      display: inline-block;
    }

    img:first-child {
      margin-right: 0.5rem;
    }

    .third-login-content {
      margin: -0.2667rem auto 0;
      width: 61%;
      height: 2.68rem;
      .gg-btn {
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }

  .x {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    width: 0.72rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.1);
      transition: all 0.2s;
    }
  }

  .login_result {
    position: absolute;
    top: 15%;
    left: calc(50% - 3.3733rem);
    width: 5.39736rem;
    height: 6.2826rem;
    background: url("../assets/img/pc_new/login_result_bg.png") no-repeat;
    background-size: contain;
    z-index: 3;

    .icon {
      width: 0.88rem;
      margin: 0.7rem auto 0.2rem;
    }

    p {
      font-size: 0.48rem;
      letter-spacing: 4px;
      color: #fff;
    }
  }

  .customer {
    position: absolute;
    top: 0;
    left: calc(50% - 4.86rem);
    width: 7.776rem;
    height: 7.36rem;
    z-index: 3;

    .x {
      top: 25.5%;
    }
  }

  .customer_center {
    background: url("../assets/img/pc_new/customer_center_bg.png") no-repeat;
    background-size: contain;

    .customer_center_item {
      margin: 46% auto 0;
      width: 55%;

      img + img {
        margin-top: 0.33rem;
      }
    }
  }

  .coin_pop_up {
    .coin_list {
      width: 70%;
      font-size: 0.48rem;
      margin: 0.5333rem auto 0;
      color: #fff;

      .coin_item {
        width: 100%;
        border: 1px solid #fff;
        border-radius: 0.2rem;
        justify-content: flex-start;
        cursor: pointer;

        img {
          width: 0.8rem;
          margin: 0.0533rem 0.2667rem 0.0533rem 0.1333rem;
        }
      }

      .coin_item + .coin_item {
        margin-top: 10px;
      }
    }

    .bt {
      font-size: 0.4rem;
      color: #8184ff;
      line-height: 1.2rem;
    }
  }
}

img {
  display: block;
  width: 100%;
}
</style>
