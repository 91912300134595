<template>
  <div class="main">
    <b-header></b-header>
    <b-tips @vip="getMsg" @line="getLine"></b-tips>
    <img src="../assets/img/bg.png" alt class="bgimg"/>
    <img src="../assets/img/logo.png" alt class="logo"/>
    <img src="../assets/img/gamelist.png" alt class="gameList"/>
    <down-loads/>
    <div class="swiper_dom">
      <div v-show="bannerList.length > 0" class="swiper-container gallery-top">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(url, index) in bannerList" :key="index">
            <a :href="url.link" target="_blank">
              <img class="detailImg" referrerpolicy="no-referrer" :src="url.images"/>
            </a>
          </div>
        </div>
      </div>
      <div v-show="bannerList.length > 0" class="swiper-container gallery-thumbs">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(url, index) in bannerList" :key="index">
            <img class="detailImg" referrerpolicy="no-referrer" :src="url.images"/>
          </div>
        </div>
      </div>
    </div>
    <div class="new_list">
      <div class="list_head">
        <div
                :class="[activeNew == 1 ? 'head_item active' : 'head_item']"
                v-on:click="chageList(1)"
        >
          遊戲公告
        </div>
        <div class="line"></div>
        <div
                :class="[activeNew == 2 ? 'head_item active' : 'head_item']"
                v-on:click="chageList(2)"
        >
          活動消息
        </div>
        <div class="line"></div>
        <a href="javascript:;" @click="goRank">
          <div class="head_item">實時排行榜</div>
        </a>
        <div class="line"></div>
        <router-link to="/newlist">
          <div class="head_item">更多+</div>
        </router-link>
      </div>
      <div class="list_detail">
        <div
                class="detail_item"
                v-for="(item, index) in massageList"
                :key="index"
                @click="gotoDeatil(item)"
        >
          <div class="titles">{{ item.message_title }}</div>
          <div class="time">{{ item.uptime }}</div>
        </div>
      </div>
    </div>
    <b-footer></b-footer>
    <transition name="scale">
      <b-Wechat @funcW="getMsg" v-show="showWechat"></b-Wechat>
    </transition>
    <transition name="scale">
      <b-Line @func="getLine" v-show="showline"></b-Line>
    </transition>
    <div
            class="mark"
            v-if="showline || showWechat"
            :style="{ height: scrollerHeight }"
    >
    </div>
  </div>
</template>

<script>
import BHeader from "@/components/head";
import BFooter from "@/components/footer";
import BTips from "@/components/tips";
import BWechat from "@/components/alert1";
import BLine from "@/components/alert2";
import axios from "axios";

import Swiper from "swiper";
import store from "../pcStore.js";
import xml2js from "xml2js";
import DownLoads from "./downLoads.vue";

export default {
  components: {BHeader, BFooter, BTips, BWechat, BLine, DownLoads},
  data() {
    return {
      msg: "hello",
      activeNew: 1,
      showWechat: false,
      isInit: 1,
      showline: false,
      massageList: [],
      bannerList: [],
      userId: "",
      nickName: "",
    };
  },
  methods: {
    // 获取实时排行版跳转链接参数
    async getUser() {
      let uuid = await store.state.pk_userInfo?.uuid;
      if (uuid) {
        axios
                .get(`https://dpk.lukycube.com:8198/MF_USERINFO.asmx/USERINFO?UUID=${uuid}`)
                .then(async (res) => {
                  if (res.status === 200) {
                    let xml = await res.data;
                    xml2js.parseString(xml, async (err, result) => {
                      if (err) {
                        console.log(err);
                      } else {
                        let userInfo = await JSON.parse(result.string._).Rows[0];
                        this.userId = await userInfo[1];
                        this.nickName = await userInfo[0];
                        window.open(
                                "https://richlist.lukycube.com/?USERID=" +
                                this.userId +
                                "&NICKNAME=" +
                                this.nickName
                        );
                      }
                    });
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                });
      } else {
        window.open("https://richlist.lukycube.com/?USERID=" + "" + "&NICKNAME=" + "");
      }
    },
    goRank() {
      this.getUser();
    },
    chageList(a) {
      this.activeNew = a;
      this.changeNew(a);
    },
    getMsg(e) {
      this.showWechat = e;
    },
    getLine(e) {
      this.showline = e;
    },
    gotoDeatil(e) {
      if (e.message_link) {
        window.open(e.message_link);
      } else {
        this.$router.push({path: "/newdetail", query: {articleId: e.id}});
      }
    },
    changeNew(e) {
      let datas = {
        num: 6,
        page: 1,
        type: e,
      };
      this.massageList = [];
      this.getHttp(this, datas, "/message", function (obj, data) {
        for (var i in data.message) {
          obj.massageList.push(data.message[i]);
        }
      });
    },
  },
  mounted() {
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 10,
      slidesPerView: 5,
      freeMode: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      observer: true,
      autoplay: true,
    });
    var galleryTop = new Swiper(".gallery-top", {
      spaceBetween: 10,
      observer: true,
      autoplay: true,
      thumbs: {
        swiper: galleryThumbs,
      },
    });
    //banner
    this.getHttp(this, "", "/banner", function (obj, data) {
      for (var i in data) {
        obj.bannerList.push(data[i]);
      }
    });
    //游戏公告
    this.changeNew(1);
  },
  computed: {
    scrollerHeight: function () {
      return document.body.Height + "px";
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: inherit;
}

.mark {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.scale-enter,
.scale-leave-to {
  transform: scale(0);
}

.scale-leave,
.scale-enter-to {
  transform: scale(1);
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.main {
  width: 100%;
  position: relative;

  .bgimg {
    width: 100%;
  }

  .gameList {
    position: absolute;
    width: 14rem;
    top: 2rem;
    left: 50%;
    margin-left: -7rem;
  }

  .logo {
    position: absolute;
    width: 2.1rem;
    top: 1.36rem;
    left: 50%;
    margin-left: -5rem;
  }
}

.swiper_dom {
  width: 5.76rem;
  position: absolute;
  left: 50%;
  margin-left: -5.6rem;
  top: 11.1rem;

  img {
    width: 100%;
    height: 100%;
  }

  .swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
  }

  .swiper-slide {
  }

  .gallery-top {
    width: 100%;
    height: 4rem;
  }

  .gallery-thumbs {
    box-sizing: border-box;
    padding: 5px 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    opacity: 0.5;
    height: 0.75rem;

    img {
      display: block;
    }
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.new_list {
  width: 5.76rem;
  position: absolute;
  left: 50%;
  margin-left: 0.3rem;
  top: 11.1rem;

  .list_head {
    width: 100%;
    height: 0.6rem;
    background-color: #577fe4;
    color: white;
    font-size: 0.22rem;
    padding-left: 0.15rem;

    .line {
      height: 0.2rem;
      width: 1px;
      background-color: white;
      float: left;
      margin-top: 0.2rem;
    }

    .head_item {
      float: left;
      line-height: 0.59rem;
      padding: 0 0.25rem;
      cursor: pointer;
    }

    .head_item:hover {
      color: #fff260;
    }

    .active {
      color: #fff260;
      border-bottom: 2px solid #fff260;
    }

    .head_item:last-child {
      border-right: 0;
    }
  }

  .list_item {
    width: 100%;
    height: 4rem;
    overflow: hidden;
  }

  .detail_item:hover {
    .titles,
    .time {
      color: #fff260;
    }
  }

  .list_detail {
    line-height: 0.65rem;
    color: white;
    font-size: 0.22rem;
    padding: 0.1rem 0.3rem;
    width: 100%;
    float: left;
    height: 4.2rem;
    background: url(../assets/img/newbg.png) no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    cursor: pointer;

    .titles {
      width: 75%;
      float: left;
      text-align: left;
      border-bottom: 1px solid white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .time {
      width: 25%;
      float: left;
      border-bottom: 1px solid white;
    }
  }
}

</style>
