<template>
  <transition name="fadeIn">
    <div>
      <div class="mask" v-if="isAccountVisible"></div>
      <div
          class="mb-dialog"
          v-if="isAccountVisible">
        <h3>註銷提醒</h3>
        <p>
          如果您不再使用此帳號，可以將其註銷。
          帳號成功註銷後，該帳號下所有遊戲的個人資訊（包括但不限頭像、昵稱、充值和消費記錄、好友關係、歷史戰績、段位與等級等）將進行刪除或匿名化處理，<span
            class="red"
        >且無法恢復</span
        >，請您謹慎操作。
        </p>
        <div class="button_group">
          <div @click="nextCancel">確認註銷</div>
          <div @click="closeDialog">取消</div>
        </div>
      </div>
      <div class="mb-dialog" v-if="show_confirm_success">
        <p style="text-align: center;position: relative; top: 0.4rem; font-size: 0.5rem">註銷成功</p>
        <span slot="footer" class="dialog-footer">
         <div class="confirm_btn" @click="finishDeaction">確認</div>
      </span>
      </div>
    </div>
  </transition>
</template>
<script>
import store from "@/store";

export default {
  name: "mbCancelAccount",
  data() {
    return {
      isAccountVisible: false,
      gameList: [],
      errorMessage: "",
      platform: "",
      appid: "",
      show_confirm_success: false,
      show_toast: false,
      toast_msg: '',
      pk_userInfo: {}
    };
  },
  beforeMount() {
    this.$watch(
        () => store.state.show_account_cancel,
        (newV, oldV) => {
          this.isAccountVisible = newV
        }
    )
  },
  async beforeCreate() {
  },
  props: {
    showDialog: Boolean,
  },

  methods: {
    finishDeaction() {
      this.show_confirm_success = false
      store.commit('logOut')
    },
    nextCancel() {
      this.deactiveAccount()
      store.commit('setAccountDeletion', false)
    },
    closeDialog() {
      store.commit('setAccountDeletion', false)
    },
    async deactiveAccount() {
      this.pk_userInfo = JSON.parse(localStorage.getItem('pk_userInfo'))
      this.postHttp(this, {token: this.pk_userInfo.token}, '/deletionAccount', function (obj, res) {
        const {code, data, message} = res
        if(code === 200) {
          obj.$layer.msg('該帳號已註銷，請重新登錄')
          obj.show_confirm_success = true
        }else {
          obj.$layer.msg(message);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mb-dialog {
  width: 9rem;
  position: absolute;
  z-index: 10;
  background: white;
  left: 0.5rem;
  top: 30vh;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: #333333;

  h3 {
    font-size: 0.5rem;
    margin-bottom: 0.4rem;
  }

  p {
    font-size: 0.4rem;
    line-height: 0.6rem;
  }

  .button_group {
    display: flex;
    justify-content: space-around;
    width: 6rem;
    margin: 0.4rem auto;

    div {
      width: 2rem;
      height: 0.8rem;
      line-height: 0.8rem;
      border-radius: 0.13rem;
      border: 1px solid #fcc916;
    }

    div:nth-child(1) {
      color: white;
      background: #fcc916;
    }
  }
}
.confirm_btn {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.13rem;
  border: 1px solid #fcc916;
  margin: 1rem auto 0.2rem auto;
}

@keyframes fade {
  0% {
    filter: blur(20);
    transform: scale(.3);
  }
  100% {
    filter: blur(0);
    transform: scale(1);
  }
}

.fadeIn-enter-active {
  animation: fade .3s;
}

.fadeIn-leave-active {
  animation: fade .3s reverse;
}
</style>
