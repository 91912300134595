<!--
 * @Descripttion: 
 * @version: 
 * @Author: liy
 * @Date: 2023-03-29 17:53:58
 * @LastEditTime: 2023-04-14 12:01:01
-->
<template>
  <div class="head_wrap">
    <div class="head">
      <div class="head_con">
        <ul class="menu_list">
          <router-link to="/" exact>
            <li class=""><a href="javascript:;">官網首頁 </a></li>
          </router-link>
          <i>/</i>
          <router-link to="/produce">
            <li><a href="javascript:;">產品介紹</a></li>
          </router-link>
          <i>/</i>
          <li @click="showlayer">客服中心</li>
          <i>/</i>
          <router-link to="/chargeCenter">
            <li>儲值中心</li>
          </router-link>
        </ul>
      </div>

      <div class="pc_login_entry flex pointer" v-if="!username">
        <img @click="loginPop" src="../assets/img/pc_new/avatar.png" alt="" />
        <span @click="loginPop">登入</span>
        <span ref="triangle" class="triangle" @click="unLoginMenu = !unLoginMenu"></span>
      </div>
      <div class="pc_login_entry flex pc_signed_in" v-else>
        <div class="account flex">
          <img src="../assets/img/pc_new/in_avatar.png" alt="" />
          <span>{{ username }}</span>
        </div>
        <span class="pointer" @click="signOut">登出</span>
        <span ref="triangle" class="triangle" @click="unLoginMenu = !unLoginMenu"></span>
      </div>
      <ul class="unlogin-menu" v-show="unLoginMenu">
        <li class="pointer" @click="showAccountDeletion">
          <a href="javascript:;">註銷帳號</a>
        </li>
      </ul>
    </div>
    <PcPopup
      v-if="showPopup || showPopup2"
      :loginPopUp="loginPopUp"
      :customerCenter="customerCenter"
      @update:loginPopUp="handleUpdateLoginPopUp"
      @update:customerCenter="handleUpdateCustomerCenter"
    />

    <account-cancel/>
  </div>
</template>

<script>
import PcPopup from "./pcPopup.vue";
import store from "../pcStore";
import { getHashSearchParam } from "../utils/common";
import axios from "axios";
import xml2js from "xml2js";
import AccountCancel from "../views/accountCancel/index.vue";

export default {
  data() {
    return {
      showPopup: false,
      loginPopUp: false,
      customerCenter: false,
      username: "",
      redirectUrl: "",
      unLoginMenu: false
    };
  },
  computed: {
    showPopup2() {
      return store.state.showLogin;
    },
  },
  components: {
    PcPopup,
    AccountCancel
  },
  created() {
    this.username = store.state.nickName || "";
    this.getParams();
  },
  methods: {
    // ...mapMutations(['setAccountDeletion']),
    showAccountDeletion(){
      this.unLoginMenu = false
      if(!this.username) {
        this.showPopup = this.loginPopUp = true
      }else {
        store.commit('setAccountDeletion', true)
      }
    },
    showlayer() {
      this.showPopup = true;
      this.customerCenter = true;
    },
    loginPop() {
      this.showPopup = true;
      this.loginPopUp = true;
      this.customerCenter = false;
    },
    handleUpdateLoginPopUp(value) {
      this.showPopup = value;
      this.loginPopUp = false;
      this.username = store.state.nickName || "";
    },
    handleUpdateCustomerCenter(value) {
      this.showPopup = value;
      this.customerCenter = false;
      this.loginPopUp = false;
    },
    signOut() {
      store.commit("logOut");
      this.username = "";
    },
    async getParams() {
      let url = window.location.href;
      let parIndex = url.indexOf("?");
      this.redirectUrl = url.slice(0, parIndex);
      let account = getHashSearchParam("account");
      if (account) {
        let type = getHashSearchParam("type");
        if (type === "google") {
          type = "Google";
          account = account.slice(3);
        } else {
          type = "FaceBook";
        }
        let data = {
          account,
          type,
          password: "",
        };
        this.login(data);
      }
    },
    login(data) {
      this.postHttp(this, data, "/login", async (obj, data) => {
        if (data.code === 200) {
          store.commit("setUserInfo", data.data);
          this.getNickName(data.data.uuid);
        } else {
          this.$layer.msg(data.message);
          this.showPopup = true;
        }
      });
    },
    getNickName(uuid) {
      if (uuid) {
        axios
          .get(`https://dpk.lukycube.com:8198/MF_USERINFO.asmx/USERINFO?UUID=${uuid}`)
          .then(async (res) => {
            if (res.status === 200) {
              let xml = await res.data;
              xml2js.parseString(xml, async (err, result) => {
                if (err) {
                  console.log(err);
                } else {
                  let userInfo = await JSON.parse(result.string._).Rows[0];
                  this.username = await userInfo[0];
                  store.commit("setNickName", userInfo[0]);
                  this.showPopup = true;
                  // window.location.href = this.redirectUrl;
                }
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scope>
.unlogin-menu {
  display: block;
  position: absolute;
  width: 2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  top: 77%;
  right: 0.5rem;
  font-size: 0.2rem;
  font-weight: bolder;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 5px 10px #8f908c;
  background: #fff;
  z-index: 10;
  a{
    text-decoration: none;
    color: black !important;
  }
  a:hover{
    color: #f08200 !important;
  }
}
.head_wrap {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1rem;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.head {
  font-size: 0.28rem;

  .head_con {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }

  .pc_login_entry {
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);

    // width: 1.2rem;
    img {
      width: 0.45rem;
      margin-right: 10px;
    }
  }

  .pc_signed_in {
    .account {
      padding: 8px;
      border-radius: 8px;
      background: #000;
      margin-right: 10px;
      color: #fff;

      img {
        width: 0.28rem;
      }
    }
  }
}

.menu_list {
  list-style: none;

  i {
    line-height: 1rem;
    margin: 0 0.5rem;
  }

  li {
    display: inline-block;
    width: 1.5rem;
    line-height: 1rem;
    cursor: pointer;
    color: #444;

    a {
      color: #444;
      text-decoration: none;
    }
  }
}

.router-link-exact-active {
  li {
    background-image: linear-gradient(0deg, #55b9ff, #546cff);
  }
}
.triangle {
  cursor: pointer;
  width: 0;
  height: 0;
  margin: 0 10px 0;
  border-left: 0.12rem solid transparent;
  border-right: 0.12rem solid transparent;
  border-top: 0.12rem solid #000000;
}
</style>
