import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import mbindex from '@/components/mb_index'
import produce from '@/components/produce'
import mycard from '@/components/mycardteach'
import appteach from '@/components/appteach'
import mybean from '@/components/mybean'
import newlist from '@/components/new'
import newdetail from '@/components/newdetail'
import chargeCenter from '@/views/chargeCenter.vue'

import mbproduce from '@/components/mb_produce'
import mbmycard from '@/components/mb_mycardteach'
import mbappteach from '@/components/mb_appteach'
import mbnewlist from '@/components/mb_new'
import mbnewdetail from '@/components/mb_new_detail'
import mbmybean from '@/components/mb_mybean'
import mbCustom from '@/components/mb_custom'
import mbCustomRule from '@/components/mb_custom_rule'
import mbCharge from "../components/mb_charge.vue";
Vue.use(Router)

var router = new Router({
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: '首页',
            component: index,
            beforeEnter: (to, from, next) => {
                if (/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
                    next({ path: '/mbindex' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/index',
            name: '首页',
            component: index,
            beforeEnter: (to, from, next) => {
                if (/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
                    next({ path: '/mbindex' })
                }else {
                    next()
                }
            }
        },
        {
            path: '/mbindex',
            name: '移动首页',
            component: mbindex
        }, {
            path: '/produce',
            name: '产品介绍',
            component: produce
        }, {
            path: '/mycard',
            name: 'mycard教程',
            component: mycard
        }, {
            path: '/appteach',
            name: 'app教程',
            component: appteach
        }, {
            path: '/newlist',
            name: '列表',
            component: newlist
        }, {
            path: '/newdetail',
            name: '詳情',
            component: newdetail
        },{
            path: '/mbproduce',
            name: '移动端产品页',
            component: mbproduce
        },{
            path: '/mbmycard',
            name: '移动端mycard页',
            component: mbmycard
        },{
            path: '/mbappteach',
            name: '移动端app页',
            component: mbappteach
        },{
            path: '/mbnewlist',
            name: '移动端列表',
            component: mbnewlist
        },{
            path: '/mybean',
            name: 'pc魔豆',
            component: mybean
        },{
            path: '/mbnewdetail',
            name: '移动端详情',
            component: mbnewdetail
        },{
            path: '/mbmybean',
            name: '移动端魔豆',
            component: mbmybean
        },
       
        {
            path: '/chargeCenter',
            name: '储值中心',
            component: chargeCenter
        },
        {
            path: '/mbCustomer',
            name: '移动端客服中心',
            component: mbCustom
        },
        {
            path: '/mbCustomerRule',
            name: '移动端客服中心游戏管理',
            component: mbCustomRule
        }, {
            path: '/mbCharge',
            name: '移动端储值中心',
            component: mbCharge
        },
    ]
})
export default router